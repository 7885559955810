@import "bootstrap/scss/nav";

@include media-breakpoint-up(lg) {
    .nav-link-badge {
        position: relative;

        .badge {
            position: relative;
            top: -15px;
            left: -5px;
        }

        .nav-link-text {
            position: relative;
            &:before {
                position: absolute;
                top: 0;
                left: -7px;
                display: block;
                width: 20px;
                height: 20px;
                margin: auto;
                content: '';
                transform: translate(-50%, -50%) rotate(100deg);
                border-left: 1px dashed #fff;
                border-radius: 10px;
            }
        }
    }
}

// Overflow nav

.nav {
    &.overflow-x {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 1px;

        .nav-item {
            width: auto;
            max-width: 100%;
            flex: 0 0 auto;
        }

        &::-webkit-scrollbar {
          display: none;
        }
    }
}

// Nav used inside containers

.nav-header {
    border-bottom-width: 0;
}

//
// Progress cards
//

.card-progress {
    margin-bottom: .75rem;

    .card-body {
        padding: 1rem;
    }

    .progress {
        height: 2px;
        margin: 2px 4px;
    }
}

//
// App buttons
// App store download buttons for Apple Store or Play Store

.btn-app {
    background: $black;
    color: $white;
    position: relative;
    padding-left: 4.5rem;
    padding-right: 1.5rem;
    text-align: left;

    i, svg {
        width: 1em;
        font-size: 2.5rem;
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translateY(-50%)
    }

    .btn-text {
        display: block;
        font-size: .75rem;
        line-height: .75rem;
        margin-bottom: .125rem;
    }

    .btn-brand {
        display: block;
        text-align: left;
        font-size: 21px;
        line-height: 21px;
    }

    &:hover {
        background-color: shade-color($primary, 7%);
        color: color-contrast($primary);
    }
}

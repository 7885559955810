//
// Card list
//

.card-list {
    background-color: $card-bg;
    border: $card-border-width solid $card-border-color;
    padding: 1rem;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);

    .card {
        box-shadow: none !important;
    }
}

.card-list-body {
    .card:last-of-type {
        margin-bottom: 0 !important;
    }
}

.empty-container {
    &:after {
        pointer-events: none;
        content: attr(data-placeholder);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-sm;
        font-weight: 500;
        color: shade-color(#eef2f5, 15%);
        border: 1px dashed shade-color(#eef2f5, 15%);
        height: 0;
        opacity: 0;
        @include border-radius($card-border-radius);
        @include transition(opacity .35s ease);
    }

    &:only-child {
        &:after {
            opacity: 1;
            height: 70px;
        }
    }
}

// Flushed card list

.card-list-flush {
    border: 0;
    border-radius: 0;
    @include box-shadow(none)
}

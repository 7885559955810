// Nav tabs


.nav-tabs {
    .nav-item {
        margin-left: $nav-tabs-link-margin-x;
        margin-right: $nav-tabs-link-margin-x;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &.show {
            .nav-link {
              border-color: transparent;
            }
        }
    }

    .nav-link {
        padding: $nav-tabs-link-padding-y 0;
        border-bottom: $nav-tabs-link-active-border-width solid transparent;
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;
    }
}

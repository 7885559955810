// Pagination tabs

.pagination-spaced {
    border-radius: 0;

    .page-link {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: $pagination-border-radius;
        width: 36px;
        height: 36px;
    }

    &.pagination-sm {
        .page-link {
            width: 30px;
            height: 30px;
            border-radius: $border-radius-sm;
        }
    }

    &.pagination-lg {
        .page-link {
            width: 52px;
            height: 52px;
        }
    }
}

//
// Icon
//

@use "sass:math";

.icon {
	font-size: math.div($icon-size, 1.75);
	line-height: 1;

	svg {
		width: $svg-icon-size;
        height: $svg-icon-size;
	}
}

.icon + .icon-text {
	padding-left: 1rem;
	width: calc(100% - #{$icon-size} - 1);
}


// Size variations

.icon-xl {
	font-size: math.div($icon-size-xl, 1.75);
}

.icon-xl + .icon-text {
	width: calc(100% - #{$icon-size-xl} - 1);
}

.icon-lg {
	font-size: math.div($icon-size-lg, 1.75);
}

.icon-lg + .icon-text {
	width: calc(100% - #{$icon-size-lg} - 1);
}

.icon-sm {
	font-size: math.div($icon-size-sm, 1.75);
}

.icon-sm + .icon-text {
	width: calc(100% - #{$icon-size-sm} - 1);
}

.icon-xs {
	font-size: math.div($icon-size-xs, 1.75);
}

.icon-xs + .icon-text {
	width: calc(100% - #{$icon-size-xs} - 1);
}

@import "bootstrap/scss/badge";

//
// Badge
//


.badge {
    text-transform: $badge-text-transfom;
    line-height: $badge-line-height;
}

// Size variations
.badge-xs {
    padding: .1rem .5rem;
    font-size: 60%;
}

.badge-md {
    padding: .4rem 1rem;
}

.badge-lg {
    padding: .6rem 1rem;
    font-size: $badge-lg-font-size;
}

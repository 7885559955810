@charset "UTF-8";
:root {
  --x-white: #FFF;
  --x-gray-50: #FAFAFA;
  --x-gray-100: #F5F9FC;
  --x-gray-200: #E7EAF0;
  --x-gray-300: #CFD6DF;
  --x-gray-400: #ABB6C5;
  --x-gray-500: #8898A9;
  --x-gray-600: #6B7B93;
  --x-gray-700: #525F7F;
  --x-gray-800: #2D3748;
  --x-gray-900: #16192C;
  --x-black: #000;
  --x-blue-100: #ccebff;
  --x-blue-200: #99d6ff;
  --x-blue-300: #66c2ff;
  --x-blue-400: #33adff;
  --x-blue-500: #0099FF;
  --x-blue-600: #007acc;
  --x-blue-700: #005c99;
  --x-blue-800: #003d66;
  --x-blue-900: #001f33;
  --x-indigo-100: #dedffd;
  --x-indigo-200: #bebffb;
  --x-indigo-300: #9da0f9;
  --x-indigo-400: #7d80f7;
  --x-indigo-500: #5C60F5;
  --x-indigo-600: #4a4dc4;
  --x-indigo-700: #373a93;
  --x-indigo-800: #252662;
  --x-indigo-900: #121331;
  --x-purple-100: #e7ddff;
  --x-purple-200: #d0bcff;
  --x-purple-300: #b89aff;
  --x-purple-400: #a179ff;
  --x-purple-500: #8957FF;
  --x-purple-600: #6e46cc;
  --x-purple-700: #523499;
  --x-purple-800: #372366;
  --x-purple-900: #1b1133;
  --x-pink-100: #ffddeb;
  --x-pink-200: #ffbcd7;
  --x-pink-300: #ff9ac2;
  --x-pink-400: #ff79ae;
  --x-pink-500: #FF579A;
  --x-pink-600: #cc467b;
  --x-pink-700: #99345c;
  --x-pink-800: #66233e;
  --x-pink-900: #33111f;
  --x-magenta-100: #fcd6ff;
  --x-magenta-200: #f8adff;
  --x-magenta-300: #f585ff;
  --x-magenta-400: #f15cff;
  --x-magenta-500: #EE33FF;
  --x-magenta-600: #be29cc;
  --x-magenta-700: #8f1f99;
  --x-magenta-800: #5f1466;
  --x-magenta-900: #300a33;
  --x-red-100: #ffd6e0;
  --x-red-200: #ffadc2;
  --x-red-300: #ff85a3;
  --x-red-400: #ff5c85;
  --x-red-500: #FF3366;
  --x-red-600: #cc2952;
  --x-red-700: #991f3d;
  --x-red-800: #661429;
  --x-red-900: #330a14;
  --x-orange-100: #ffe8cc;
  --x-orange-200: #ffd199;
  --x-orange-300: #ffba66;
  --x-orange-400: #ffa333;
  --x-orange-500: #FF8C00;
  --x-orange-600: #cc7000;
  --x-orange-700: #995400;
  --x-orange-800: #663800;
  --x-orange-900: #331c00;
  --x-yellow-100: #fff1cc;
  --x-yellow-200: #ffe499;
  --x-yellow-300: #ffd666;
  --x-yellow-400: #ffc933;
  --x-yellow-500: #FFBB00;
  --x-yellow-600: #cc9600;
  --x-yellow-700: #997000;
  --x-yellow-800: #664b00;
  --x-yellow-900: #332500;
  --x-green-100: #ccf5e7;
  --x-green-200: #99ebcf;
  --x-green-300: #66e0b8;
  --x-green-400: #33d6a0;
  --x-green-500: #00CC88;
  --x-green-600: #00a36d;
  --x-green-700: #007a52;
  --x-green-800: #005236;
  --x-green-900: #00291b;
  --x-teal-100: #d3f8f8;
  --x-teal-200: #a7f1f1;
  --x-teal-300: #7aebeb;
  --x-teal-400: #4ee4e4;
  --x-teal-500: #22DDDD;
  --x-teal-600: #1bb1b1;
  --x-teal-700: #148585;
  --x-teal-800: #0e5858;
  --x-teal-900: #072c2c;
  --x-cyan-100: #ccf6ff;
  --x-cyan-200: #99eeff;
  --x-cyan-300: #66e5ff;
  --x-cyan-400: #33ddff;
  --x-cyan-500: #00D4FF;
  --x-cyan-600: #00aacc;
  --x-cyan-700: #007f99;
  --x-cyan-800: #005566;
  --x-cyan-900: #002a33;
  --x-primary: #5C60F5;
  --x-shade-primary: #5356dd;
  --x-tint-primary: #6c70f6;
  --x-secondary: #CFD6DF;
  --x-shade-secondary: #bac1c9;
  --x-tint-secondary: #d4dae2;
  --x-tertiary: #FF579A;
  --x-shade-tertiary: #e64e8b;
  --x-tint-tertiary: #ff68a4;
  --x-success: #00CC88;
  --x-shade-success: #00b87a;
  --x-tint-success: #1ad194;
  --x-info: #00D4FF;
  --x-shade-info: #00bfe6;
  --x-tint-info: #1ad8ff;
  --x-warning: #FF8C00;
  --x-shade-warning: #e67e00;
  --x-tint-warning: #ff981a;
  --x-danger: #FF3366;
  --x-shade-danger: #e62e5c;
  --x-tint-danger: #ff4775;
  --x-white: #FFF;
  --x-shade-white: #e6e6e6;
  --x-tint-white: white;
  --x-light: #FFF;
  --x-shade-light: #e6e6e6;
  --x-tint-light: white;
  --x-dark: #16192C;
  --x-shade-dark: #141728;
  --x-tint-dark: #2d3041;
  --x-primary-rgb: 92, 96, 245;
  --x-secondary-rgb: 207, 214, 223;
  --x-tertiary-rgb: 255, 87, 154;
  --x-success-rgb: 0, 204, 136;
  --x-info-rgb: 0, 212, 255;
  --x-warning-rgb: 255, 140, 0;
  --x-danger-rgb: 255, 51, 102;
  --x-white-rgb: 255, 255, 255;
  --x-light-rgb: 255, 255, 255;
  --x-dark-rgb: 22, 25, 44;
  --x-surface-primary: #FFF;
  --x-surface-secondary: #F5F9FC;
  --x-surface-tertiary: #FAFAFA;
  --x-surface-light: #E7EAF0;
  --x-surface-dark: #141729;
  --x-white-rgb: 255, 255, 255;
  --x-black-rgb: 0, 0, 0;
  --x-muted-rgb: 107, 123, 147;
  --x-body-rgb: 82, 95, 127;
  --x-heading-rgb: 22, 25, 44;
  --x-card-rgb: 255, 255, 255;
  --x-font-sans-serif: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --x-font-monospace: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --x-font-display: Inter, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --x-font-serif: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  --x-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --x-body-font-family: var(--x-font-sans-serif);
  --x-body-font-size: 1rem;
  --x-body-font-weight: 400;
  --x-body-line-height: 1.625;
  --x-body-color: #525F7F;
  --x-body-bg: #FFF;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--x-body-font-family);
  font-size: var(--x-body-font-size);
  font-weight: var(--x-body-font-weight);
  line-height: var(--x-body-line-height);
  color: var(--x-body-color);
  text-align: var(--x-body-text-align);
  background-color: var(--x-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
hr {
  margin: 1rem 0;
  color: #E7EAF0;
  background-color: currentColor;
  border: 0;
  opacity: 0.9;
}
hr:not([size]) {
  height: 1px;
}
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  line-height: 1.3;
  color: #16192C;
}
h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}
h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}
h3, .h3 {
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.375rem;
  }
}
h4, .h4 {
  font-size: 1.125rem;
}
h5, .h5 {
  font-size: 1rem;
}
h6, .h6 {
  font-size: 0.875rem;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  padding-left: 2rem;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 600;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small, .small {
  font-size: 0.875em;
}
mark, .mark {
  padding: 0;
  background-color: #fcf8e3;
}
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #5C60F5;
  text-decoration: none;
}
a:hover {
  color: #4e52d0;
  text-decoration: none;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
pre,
code,
kbd,
samp {
  font-family: var(--x-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}
pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
code {
  font-size: 0.875em;
  color: #FF579A;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #FFF;
  background-color: #16192C;
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #6B7B93;
  text-align: left;
}
th {
  font-weight: 500;
  text-align: inherit;
  text-align: -webkit-match-parent;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role=button] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}
::-webkit-inner-spin-button {
  height: auto;
}
[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}
/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-color-swatch-wrapper {
  padding: 0;
}
::-webkit-file-upload-button {
  font: inherit;
}
::file-selector-button {
  font: inherit;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
iframe {
  border: 0;
}
summary {
  display: list-item;
  cursor: pointer;
}
progress {
  vertical-align: baseline;
}
[hidden] {
  display: none !important;
}
*,
*::before,
*::after {
  border: 0 solid #E7EAF0;
}
iframe {
  display: block;
  vertical-align: middle;
}
figcaption,
figure,
main {
  display: block;
  margin: 0;
}
figure {
  max-width: 100%;
  min-height: 1px;
}
img,
video {
  max-width: 100%;
  height: auto;
}
svg {
  vertical-align: middle;
  overflow: hidden;
}
a {
  outline: none;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #CFD6DF;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 1px 0px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 0.875em;
  color: #6B7B93;
}
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--x-gutter-x, 1.5rem);
  padding-left: var(--x-gutter-x, 1.5rem);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1360px;
  }
}
.row {
  --x-gutter-x: 1.5rem;
  --x-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--x-gutter-y));
  margin-right: calc(-0.5 * var(--x-gutter-x));
  margin-left: calc(-0.5 * var(--x-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--x-gutter-x) * 0.5);
  padding-left: calc(var(--x-gutter-x) * 0.5);
  margin-top: var(--x-gutter-y);
}
.grid {
  display: grid;
  grid-template-rows: repeat(var(--x-rows, 1), 1fr);
  grid-template-columns: repeat(var(--x-columns, 12), 1fr);
  gap: var(--x-gap, 1.5rem);
}
.grid .g-col-1 {
  grid-column: auto/span 1;
}
.grid .g-col-2 {
  grid-column: auto/span 2;
}
.grid .g-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4 {
  grid-column: auto/span 4;
}
.grid .g-col-5 {
  grid-column: auto/span 5;
}
.grid .g-col-6 {
  grid-column: auto/span 6;
}
.grid .g-col-7 {
  grid-column: auto/span 7;
}
.grid .g-col-8 {
  grid-column: auto/span 8;
}
.grid .g-col-9 {
  grid-column: auto/span 9;
}
.grid .g-col-10 {
  grid-column: auto/span 10;
}
.grid .g-col-11 {
  grid-column: auto/span 11;
}
.grid .g-col-12 {
  grid-column: auto/span 12;
}
.grid .g-start-1 {
  grid-column-start: 1;
}
.grid .g-start-2 {
  grid-column-start: 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-start-4 {
  grid-column-start: 4;
}
.grid .g-start-5 {
  grid-column-start: 5;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.grid .g-start-7 {
  grid-column-start: 7;
}
.grid .g-start-8 {
  grid-column-start: 8;
}
.grid .g-start-9 {
  grid-column-start: 9;
}
.grid .g-start-10 {
  grid-column-start: 10;
}
.grid .g-start-11 {
  grid-column-start: 11;
}
@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-sm-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-sm-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-sm-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-sm-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-sm-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-sm-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-sm-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-sm-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }
  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }
  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }
  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }
  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }
  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }
  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }
  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }
  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }
  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }
  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-md-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-md-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-md-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-md-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-md-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-md-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-md-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-md-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-md-1 {
    grid-column-start: 1;
  }
  .grid .g-start-md-2 {
    grid-column-start: 2;
  }
  .grid .g-start-md-3 {
    grid-column-start: 3;
  }
  .grid .g-start-md-4 {
    grid-column-start: 4;
  }
  .grid .g-start-md-5 {
    grid-column-start: 5;
  }
  .grid .g-start-md-6 {
    grid-column-start: 6;
  }
  .grid .g-start-md-7 {
    grid-column-start: 7;
  }
  .grid .g-start-md-8 {
    grid-column-start: 8;
  }
  .grid .g-start-md-9 {
    grid-column-start: 9;
  }
  .grid .g-start-md-10 {
    grid-column-start: 10;
  }
  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-lg-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-lg-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-lg-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-lg-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-lg-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-lg-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-lg-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-lg-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-lg-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }
  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }
  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }
  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }
  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }
  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }
  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }
  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }
  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }
  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }
  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xxl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xxl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xxl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xxl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xxl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xxl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xxl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xxl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xxl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xxl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xxl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}
.col {
  flex: 1 0 0%;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.offset-1 {
  margin-left: 8.33333333%;
}
.offset-2 {
  margin-left: 16.66666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33333333%;
}
.offset-5 {
  margin-left: 41.66666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.33333333%;
}
.offset-8 {
  margin-left: 66.66666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.33333333%;
}
.offset-11 {
  margin-left: 91.66666667%;
}
.g-0,
.gx-0 {
  --x-gutter-x: 0;
}
.g-0,
.gy-0 {
  --x-gutter-y: 0;
}
.g-1,
.gx-1 {
  --x-gutter-x: 0.25rem;
}
.g-1,
.gy-1 {
  --x-gutter-y: 0.25rem;
}
.g-2,
.gx-2 {
  --x-gutter-x: 0.5rem;
}
.g-2,
.gy-2 {
  --x-gutter-y: 0.5rem;
}
.g-3,
.gx-3 {
  --x-gutter-x: 0.75rem;
}
.g-3,
.gy-3 {
  --x-gutter-y: 0.75rem;
}
.g-4,
.gx-4 {
  --x-gutter-x: 1rem;
}
.g-4,
.gy-4 {
  --x-gutter-y: 1rem;
}
.g-5,
.gx-5 {
  --x-gutter-x: 1.25rem;
}
.g-5,
.gy-5 {
  --x-gutter-y: 1.25rem;
}
.g-6,
.gx-6 {
  --x-gutter-x: 1.5rem;
}
.g-6,
.gy-6 {
  --x-gutter-y: 1.5rem;
}
.g-7,
.gx-7 {
  --x-gutter-x: 1.75rem;
}
.g-7,
.gy-7 {
  --x-gutter-y: 1.75rem;
}
.g-8,
.gx-8 {
  --x-gutter-x: 2rem;
}
.g-8,
.gy-8 {
  --x-gutter-y: 2rem;
}
.g-10,
.gx-10 {
  --x-gutter-x: 2.5rem;
}
.g-10,
.gy-10 {
  --x-gutter-y: 2.5rem;
}
.g-12,
.gx-12 {
  --x-gutter-x: 3rem;
}
.g-12,
.gy-12 {
  --x-gutter-y: 3rem;
}
.g-14,
.gx-14 {
  --x-gutter-x: 3.5rem;
}
.g-14,
.gy-14 {
  --x-gutter-y: 3.5rem;
}
.g-16,
.gx-16 {
  --x-gutter-x: 4rem;
}
.g-16,
.gy-16 {
  --x-gutter-y: 4rem;
}
.g-18,
.gx-18 {
  --x-gutter-x: 4.5rem;
}
.g-18,
.gy-18 {
  --x-gutter-y: 4.5rem;
}
.g-20,
.gx-20 {
  --x-gutter-x: 5rem;
}
.g-20,
.gy-20 {
  --x-gutter-y: 5rem;
}
.g-24,
.gx-24 {
  --x-gutter-x: 6rem;
}
.g-24,
.gy-24 {
  --x-gutter-y: 6rem;
}
.g-32,
.gx-32 {
  --x-gutter-x: 8rem;
}
.g-32,
.gy-32 {
  --x-gutter-y: 8rem;
}
.g-40,
.gx-40 {
  --x-gutter-x: 10rem;
}
.g-40,
.gy-40 {
  --x-gutter-y: 10rem;
}
.g-48,
.gx-48 {
  --x-gutter-x: 12rem;
}
.g-48,
.gy-48 {
  --x-gutter-y: 12rem;
}
.g-56,
.gx-56 {
  --x-gutter-x: 14rem;
}
.g-56,
.gy-56 {
  --x-gutter-y: 14rem;
}
.g-64,
.gx-64 {
  --x-gutter-x: 16rem;
}
.g-64,
.gy-64 {
  --x-gutter-y: 16rem;
}
.g-72,
.gx-72 {
  --x-gutter-x: 18rem;
}
.g-72,
.gy-72 {
  --x-gutter-y: 18rem;
}
.g-80,
.gx-80 {
  --x-gutter-x: 20rem;
}
.g-80,
.gy-80 {
  --x-gutter-y: 20rem;
}
.g-88,
.gx-88 {
  --x-gutter-x: 22rem;
}
.g-88,
.gy-88 {
  --x-gutter-y: 22rem;
}
.g-96,
.gx-96 {
  --x-gutter-x: 24rem;
}
.g-96,
.gy-96 {
  --x-gutter-y: 24rem;
}
.g-px,
.gx-px {
  --x-gutter-x: 1px;
}
.g-px,
.gy-px {
  --x-gutter-y: 1px;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --x-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --x-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --x-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --x-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --x-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --x-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --x-gutter-x: 0.75rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --x-gutter-y: 0.75rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --x-gutter-x: 1rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --x-gutter-y: 1rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-sm-6,
.gx-sm-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-sm-6,
.gy-sm-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-sm-7,
.gx-sm-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-sm-7,
.gy-sm-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-sm-8,
.gx-sm-8 {
    --x-gutter-x: 2rem;
  }

  .g-sm-8,
.gy-sm-8 {
    --x-gutter-y: 2rem;
  }

  .g-sm-10,
.gx-sm-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-sm-10,
.gy-sm-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-sm-12,
.gx-sm-12 {
    --x-gutter-x: 3rem;
  }

  .g-sm-12,
.gy-sm-12 {
    --x-gutter-y: 3rem;
  }

  .g-sm-14,
.gx-sm-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-sm-14,
.gy-sm-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-sm-16,
.gx-sm-16 {
    --x-gutter-x: 4rem;
  }

  .g-sm-16,
.gy-sm-16 {
    --x-gutter-y: 4rem;
  }

  .g-sm-18,
.gx-sm-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-sm-18,
.gy-sm-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-sm-20,
.gx-sm-20 {
    --x-gutter-x: 5rem;
  }

  .g-sm-20,
.gy-sm-20 {
    --x-gutter-y: 5rem;
  }

  .g-sm-24,
.gx-sm-24 {
    --x-gutter-x: 6rem;
  }

  .g-sm-24,
.gy-sm-24 {
    --x-gutter-y: 6rem;
  }

  .g-sm-32,
.gx-sm-32 {
    --x-gutter-x: 8rem;
  }

  .g-sm-32,
.gy-sm-32 {
    --x-gutter-y: 8rem;
  }

  .g-sm-40,
.gx-sm-40 {
    --x-gutter-x: 10rem;
  }

  .g-sm-40,
.gy-sm-40 {
    --x-gutter-y: 10rem;
  }

  .g-sm-48,
.gx-sm-48 {
    --x-gutter-x: 12rem;
  }

  .g-sm-48,
.gy-sm-48 {
    --x-gutter-y: 12rem;
  }

  .g-sm-56,
.gx-sm-56 {
    --x-gutter-x: 14rem;
  }

  .g-sm-56,
.gy-sm-56 {
    --x-gutter-y: 14rem;
  }

  .g-sm-64,
.gx-sm-64 {
    --x-gutter-x: 16rem;
  }

  .g-sm-64,
.gy-sm-64 {
    --x-gutter-y: 16rem;
  }

  .g-sm-72,
.gx-sm-72 {
    --x-gutter-x: 18rem;
  }

  .g-sm-72,
.gy-sm-72 {
    --x-gutter-y: 18rem;
  }

  .g-sm-80,
.gx-sm-80 {
    --x-gutter-x: 20rem;
  }

  .g-sm-80,
.gy-sm-80 {
    --x-gutter-y: 20rem;
  }

  .g-sm-88,
.gx-sm-88 {
    --x-gutter-x: 22rem;
  }

  .g-sm-88,
.gy-sm-88 {
    --x-gutter-y: 22rem;
  }

  .g-sm-96,
.gx-sm-96 {
    --x-gutter-x: 24rem;
  }

  .g-sm-96,
.gy-sm-96 {
    --x-gutter-y: 24rem;
  }

  .g-sm-px,
.gx-sm-px {
    --x-gutter-x: 1px;
  }

  .g-sm-px,
.gy-sm-px {
    --x-gutter-y: 1px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --x-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --x-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --x-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --x-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --x-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --x-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --x-gutter-x: 0.75rem;
  }

  .g-md-3,
.gy-md-3 {
    --x-gutter-y: 0.75rem;
  }

  .g-md-4,
.gx-md-4 {
    --x-gutter-x: 1rem;
  }

  .g-md-4,
.gy-md-4 {
    --x-gutter-y: 1rem;
  }

  .g-md-5,
.gx-md-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-md-5,
.gy-md-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-md-6,
.gx-md-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-md-6,
.gy-md-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-md-7,
.gx-md-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-md-7,
.gy-md-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-md-8,
.gx-md-8 {
    --x-gutter-x: 2rem;
  }

  .g-md-8,
.gy-md-8 {
    --x-gutter-y: 2rem;
  }

  .g-md-10,
.gx-md-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-md-10,
.gy-md-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-md-12,
.gx-md-12 {
    --x-gutter-x: 3rem;
  }

  .g-md-12,
.gy-md-12 {
    --x-gutter-y: 3rem;
  }

  .g-md-14,
.gx-md-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-md-14,
.gy-md-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-md-16,
.gx-md-16 {
    --x-gutter-x: 4rem;
  }

  .g-md-16,
.gy-md-16 {
    --x-gutter-y: 4rem;
  }

  .g-md-18,
.gx-md-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-md-18,
.gy-md-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-md-20,
.gx-md-20 {
    --x-gutter-x: 5rem;
  }

  .g-md-20,
.gy-md-20 {
    --x-gutter-y: 5rem;
  }

  .g-md-24,
.gx-md-24 {
    --x-gutter-x: 6rem;
  }

  .g-md-24,
.gy-md-24 {
    --x-gutter-y: 6rem;
  }

  .g-md-32,
.gx-md-32 {
    --x-gutter-x: 8rem;
  }

  .g-md-32,
.gy-md-32 {
    --x-gutter-y: 8rem;
  }

  .g-md-40,
.gx-md-40 {
    --x-gutter-x: 10rem;
  }

  .g-md-40,
.gy-md-40 {
    --x-gutter-y: 10rem;
  }

  .g-md-48,
.gx-md-48 {
    --x-gutter-x: 12rem;
  }

  .g-md-48,
.gy-md-48 {
    --x-gutter-y: 12rem;
  }

  .g-md-56,
.gx-md-56 {
    --x-gutter-x: 14rem;
  }

  .g-md-56,
.gy-md-56 {
    --x-gutter-y: 14rem;
  }

  .g-md-64,
.gx-md-64 {
    --x-gutter-x: 16rem;
  }

  .g-md-64,
.gy-md-64 {
    --x-gutter-y: 16rem;
  }

  .g-md-72,
.gx-md-72 {
    --x-gutter-x: 18rem;
  }

  .g-md-72,
.gy-md-72 {
    --x-gutter-y: 18rem;
  }

  .g-md-80,
.gx-md-80 {
    --x-gutter-x: 20rem;
  }

  .g-md-80,
.gy-md-80 {
    --x-gutter-y: 20rem;
  }

  .g-md-88,
.gx-md-88 {
    --x-gutter-x: 22rem;
  }

  .g-md-88,
.gy-md-88 {
    --x-gutter-y: 22rem;
  }

  .g-md-96,
.gx-md-96 {
    --x-gutter-x: 24rem;
  }

  .g-md-96,
.gy-md-96 {
    --x-gutter-y: 24rem;
  }

  .g-md-px,
.gx-md-px {
    --x-gutter-x: 1px;
  }

  .g-md-px,
.gy-md-px {
    --x-gutter-y: 1px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --x-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --x-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --x-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --x-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --x-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --x-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --x-gutter-x: 0.75rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --x-gutter-y: 0.75rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --x-gutter-x: 1rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --x-gutter-y: 1rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-lg-6,
.gx-lg-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-lg-6,
.gy-lg-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-lg-7,
.gx-lg-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-lg-7,
.gy-lg-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-lg-8,
.gx-lg-8 {
    --x-gutter-x: 2rem;
  }

  .g-lg-8,
.gy-lg-8 {
    --x-gutter-y: 2rem;
  }

  .g-lg-10,
.gx-lg-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-lg-10,
.gy-lg-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-lg-12,
.gx-lg-12 {
    --x-gutter-x: 3rem;
  }

  .g-lg-12,
.gy-lg-12 {
    --x-gutter-y: 3rem;
  }

  .g-lg-14,
.gx-lg-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-lg-14,
.gy-lg-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-lg-16,
.gx-lg-16 {
    --x-gutter-x: 4rem;
  }

  .g-lg-16,
.gy-lg-16 {
    --x-gutter-y: 4rem;
  }

  .g-lg-18,
.gx-lg-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-lg-18,
.gy-lg-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-lg-20,
.gx-lg-20 {
    --x-gutter-x: 5rem;
  }

  .g-lg-20,
.gy-lg-20 {
    --x-gutter-y: 5rem;
  }

  .g-lg-24,
.gx-lg-24 {
    --x-gutter-x: 6rem;
  }

  .g-lg-24,
.gy-lg-24 {
    --x-gutter-y: 6rem;
  }

  .g-lg-32,
.gx-lg-32 {
    --x-gutter-x: 8rem;
  }

  .g-lg-32,
.gy-lg-32 {
    --x-gutter-y: 8rem;
  }

  .g-lg-40,
.gx-lg-40 {
    --x-gutter-x: 10rem;
  }

  .g-lg-40,
.gy-lg-40 {
    --x-gutter-y: 10rem;
  }

  .g-lg-48,
.gx-lg-48 {
    --x-gutter-x: 12rem;
  }

  .g-lg-48,
.gy-lg-48 {
    --x-gutter-y: 12rem;
  }

  .g-lg-56,
.gx-lg-56 {
    --x-gutter-x: 14rem;
  }

  .g-lg-56,
.gy-lg-56 {
    --x-gutter-y: 14rem;
  }

  .g-lg-64,
.gx-lg-64 {
    --x-gutter-x: 16rem;
  }

  .g-lg-64,
.gy-lg-64 {
    --x-gutter-y: 16rem;
  }

  .g-lg-72,
.gx-lg-72 {
    --x-gutter-x: 18rem;
  }

  .g-lg-72,
.gy-lg-72 {
    --x-gutter-y: 18rem;
  }

  .g-lg-80,
.gx-lg-80 {
    --x-gutter-x: 20rem;
  }

  .g-lg-80,
.gy-lg-80 {
    --x-gutter-y: 20rem;
  }

  .g-lg-88,
.gx-lg-88 {
    --x-gutter-x: 22rem;
  }

  .g-lg-88,
.gy-lg-88 {
    --x-gutter-y: 22rem;
  }

  .g-lg-96,
.gx-lg-96 {
    --x-gutter-x: 24rem;
  }

  .g-lg-96,
.gy-lg-96 {
    --x-gutter-y: 24rem;
  }

  .g-lg-px,
.gx-lg-px {
    --x-gutter-x: 1px;
  }

  .g-lg-px,
.gy-lg-px {
    --x-gutter-y: 1px;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --x-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --x-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --x-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --x-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --x-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --x-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --x-gutter-x: 0.75rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --x-gutter-y: 0.75rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xl-6,
.gx-xl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xl-6,
.gy-xl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xl-7,
.gx-xl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xl-7,
.gy-xl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xl-8,
.gx-xl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xl-8,
.gy-xl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xl-10,
.gx-xl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xl-10,
.gy-xl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xl-12,
.gx-xl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xl-12,
.gy-xl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xl-14,
.gx-xl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xl-14,
.gy-xl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xl-16,
.gx-xl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xl-16,
.gy-xl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xl-18,
.gx-xl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xl-18,
.gy-xl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xl-20,
.gx-xl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xl-20,
.gy-xl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xl-24,
.gx-xl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xl-24,
.gy-xl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xl-32,
.gx-xl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xl-32,
.gy-xl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xl-40,
.gx-xl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xl-40,
.gy-xl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xl-48,
.gx-xl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xl-48,
.gy-xl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xl-56,
.gx-xl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xl-56,
.gy-xl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xl-64,
.gx-xl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xl-64,
.gy-xl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xl-72,
.gx-xl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xl-72,
.gy-xl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xl-80,
.gx-xl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xl-80,
.gy-xl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xl-88,
.gx-xl-88 {
    --x-gutter-x: 22rem;
  }

  .g-xl-88,
.gy-xl-88 {
    --x-gutter-y: 22rem;
  }

  .g-xl-96,
.gx-xl-96 {
    --x-gutter-x: 24rem;
  }

  .g-xl-96,
.gy-xl-96 {
    --x-gutter-y: 24rem;
  }

  .g-xl-px,
.gx-xl-px {
    --x-gutter-x: 1px;
  }

  .g-xl-px,
.gy-xl-px {
    --x-gutter-y: 1px;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --x-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --x-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --x-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --x-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --x-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --x-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --x-gutter-x: 0.75rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --x-gutter-y: 0.75rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --x-gutter-x: 1rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --x-gutter-y: 1rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --x-gutter-x: 1.25rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --x-gutter-y: 1.25rem;
  }

  .g-xxl-6,
.gx-xxl-6 {
    --x-gutter-x: 1.5rem;
  }

  .g-xxl-6,
.gy-xxl-6 {
    --x-gutter-y: 1.5rem;
  }

  .g-xxl-7,
.gx-xxl-7 {
    --x-gutter-x: 1.75rem;
  }

  .g-xxl-7,
.gy-xxl-7 {
    --x-gutter-y: 1.75rem;
  }

  .g-xxl-8,
.gx-xxl-8 {
    --x-gutter-x: 2rem;
  }

  .g-xxl-8,
.gy-xxl-8 {
    --x-gutter-y: 2rem;
  }

  .g-xxl-10,
.gx-xxl-10 {
    --x-gutter-x: 2.5rem;
  }

  .g-xxl-10,
.gy-xxl-10 {
    --x-gutter-y: 2.5rem;
  }

  .g-xxl-12,
.gx-xxl-12 {
    --x-gutter-x: 3rem;
  }

  .g-xxl-12,
.gy-xxl-12 {
    --x-gutter-y: 3rem;
  }

  .g-xxl-14,
.gx-xxl-14 {
    --x-gutter-x: 3.5rem;
  }

  .g-xxl-14,
.gy-xxl-14 {
    --x-gutter-y: 3.5rem;
  }

  .g-xxl-16,
.gx-xxl-16 {
    --x-gutter-x: 4rem;
  }

  .g-xxl-16,
.gy-xxl-16 {
    --x-gutter-y: 4rem;
  }

  .g-xxl-18,
.gx-xxl-18 {
    --x-gutter-x: 4.5rem;
  }

  .g-xxl-18,
.gy-xxl-18 {
    --x-gutter-y: 4.5rem;
  }

  .g-xxl-20,
.gx-xxl-20 {
    --x-gutter-x: 5rem;
  }

  .g-xxl-20,
.gy-xxl-20 {
    --x-gutter-y: 5rem;
  }

  .g-xxl-24,
.gx-xxl-24 {
    --x-gutter-x: 6rem;
  }

  .g-xxl-24,
.gy-xxl-24 {
    --x-gutter-y: 6rem;
  }

  .g-xxl-32,
.gx-xxl-32 {
    --x-gutter-x: 8rem;
  }

  .g-xxl-32,
.gy-xxl-32 {
    --x-gutter-y: 8rem;
  }

  .g-xxl-40,
.gx-xxl-40 {
    --x-gutter-x: 10rem;
  }

  .g-xxl-40,
.gy-xxl-40 {
    --x-gutter-y: 10rem;
  }

  .g-xxl-48,
.gx-xxl-48 {
    --x-gutter-x: 12rem;
  }

  .g-xxl-48,
.gy-xxl-48 {
    --x-gutter-y: 12rem;
  }

  .g-xxl-56,
.gx-xxl-56 {
    --x-gutter-x: 14rem;
  }

  .g-xxl-56,
.gy-xxl-56 {
    --x-gutter-y: 14rem;
  }

  .g-xxl-64,
.gx-xxl-64 {
    --x-gutter-x: 16rem;
  }

  .g-xxl-64,
.gy-xxl-64 {
    --x-gutter-y: 16rem;
  }

  .g-xxl-72,
.gx-xxl-72 {
    --x-gutter-x: 18rem;
  }

  .g-xxl-72,
.gy-xxl-72 {
    --x-gutter-y: 18rem;
  }

  .g-xxl-80,
.gx-xxl-80 {
    --x-gutter-x: 20rem;
  }

  .g-xxl-80,
.gy-xxl-80 {
    --x-gutter-y: 20rem;
  }

  .g-xxl-88,
.gx-xxl-88 {
    --x-gutter-x: 22rem;
  }

  .g-xxl-88,
.gy-xxl-88 {
    --x-gutter-y: 22rem;
  }

  .g-xxl-96,
.gx-xxl-96 {
    --x-gutter-x: 24rem;
  }

  .g-xxl-96,
.gy-xxl-96 {
    --x-gutter-y: 24rem;
  }

  .g-xxl-px,
.gx-xxl-px {
    --x-gutter-x: 1px;
  }

  .g-xxl-px,
.gy-xxl-px {
    --x-gutter-y: 1px;
  }
}
.lead {
  font-size: 1.125rem;
  font-weight: 400;
}
.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: bolder;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: bolder;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: bolder;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: bolder;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: bolder;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: bolder;
  line-height: 1.3;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}
.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6B7B93;
}
.blockquote-footer::before {
  content: "— ";
}
.surtitle {
  color: #525F7F;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.text-highlight-primary {
  background-image: linear-gradient(transparent 66%, rgba(92, 96, 245, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-secondary {
  background-image: linear-gradient(transparent 66%, rgba(207, 214, 223, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-tertiary {
  background-image: linear-gradient(transparent 66%, rgba(255, 87, 154, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-success {
  background-image: linear-gradient(transparent 66%, rgba(0, 204, 136, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-info {
  background-image: linear-gradient(transparent 66%, rgba(0, 212, 255, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-warning {
  background-image: linear-gradient(transparent 66%, rgba(255, 140, 0, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-danger {
  background-image: linear-gradient(transparent 66%, rgba(255, 51, 102, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-white {
  background-image: linear-gradient(transparent 66%, rgba(255, 255, 255, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-light {
  background-image: linear-gradient(transparent 66%, rgba(255, 255, 255, 0.15) 34%);
  background-repeat: repeat-x;
}
.text-highlight-dark {
  background-image: linear-gradient(transparent 66%, rgba(22, 25, 44, 0.15) 34%);
  background-repeat: repeat-x;
}
.dropcaps > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.dropcaps > p:first-child:first-letter {
  font-size: 3.5em;
  font-weight: 700;
  float: left;
  line-height: 1;
  margin-top: 0.05em;
  margin-right: 0.15em;
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #16192C;
}
.col-form-label {
  padding-top: calc(0.75rem + 1px);
  padding-bottom: calc(0.75rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 500;
  line-height: 1.3;
  color: #16192C;
}
.col-form-label-lg {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  font-size: 1rem;
}
.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6B7B93;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #16192C;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #E7EAF0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #16192C;
  background-color: #FFF;
  border-color: #5C60F5;
  outline: 0;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.3em;
}
.form-control::-moz-placeholder {
  color: #8898A9;
  opacity: 1;
}
.form-control::placeholder {
  color: #8898A9;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #E7EAF0;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.75rem 1.25rem;
  margin: -0.75rem -1.25rem;
  -webkit-margin-end: 1.25rem;
          margin-inline-end: 1.25rem;
  color: #16192C;
  background-color: #F5F9FC;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.75rem 1.25rem;
  margin: -0.75rem -1.25rem;
  -webkit-margin-end: 1.25rem;
          margin-inline-end: 1.25rem;
  color: #16192C;
  background-color: #F5F9FC;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e9edef;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #e9edef;
}
.form-control::-webkit-file-upload-button {
  padding: 0.75rem 1.25rem;
  margin: -0.75rem -1.25rem;
  -webkit-margin-end: 1.25rem;
          margin-inline-end: 1.25rem;
  color: #16192C;
  background-color: #F5F9FC;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #e9edef;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.75rem 0;
  margin-bottom: 0;
  line-height: 1.3;
  color: #525F7F;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.3em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-sm::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
}
.form-control-lg {
  min-height: calc(1.3em + 2rem + 2px);
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem;
  -webkit-margin-end: 1.5rem;
          margin-inline-end: 1.5rem;
}
.form-control-lg::file-selector-button {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem;
  -webkit-margin-end: 1.5rem;
          margin-inline-end: 1.5rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem;
  -webkit-margin-end: 1.5rem;
          margin-inline-end: 1.5rem;
}
textarea.form-control {
  min-height: calc(1.3em + 1.5rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.3em + 1rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.3em + 2rem + 2px);
}
.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.75rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.3em;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.3em;
  border-radius: 0.375rem;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.75rem 3.75rem 0.75rem 1.25rem;
  -moz-padding-start: calc(1.25rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #16192C;
  background-color: #FFF;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 16px 12px;
  border: 1px solid #E7EAF0;
  border-radius: 0.375rem;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #5C60F5;
  outline: 0;
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1.25rem;
  background-image: none;
}
.form-select:disabled {
  color: #8898A9;
  background-color: #E7EAF0;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #16192C;
}
.form-select-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
}
.form-select-lg {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.form-check {
  display: block;
  min-height: 1.625rem;
  padding-left: 1.5em;
  margin-bottom: 0;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3125em;
  vertical-align: top;
  background-color: #FFF;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #5C60F5;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.form-check-input:checked {
  background-color: #5C60F5;
  border-color: #5C60F5;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23FFF'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #5C60F5;
  border-color: #5C60F5;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}
.form-switch {
  padding-left: 3.375em;
}
.form-switch .form-check-input {
  width: 2.875em;
  margin-left: -3.375em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2.875em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%235C60F5'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e");
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.form-range {
  width: 100%;
  height: calc(1rem + 6px);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FFF, 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FFF, 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #5C60F5;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #cecffc;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #CFD6DF;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #5C60F5;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #cecffc;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #CFD6DF;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #8898A9;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #8898A9;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 1.25rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 1.25rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3;
  color: #6B7B93;
  text-align: center;
  white-space: nowrap;
  background-color: #F5F9FC;
  border: 1px solid #E7EAF0;
  border-radius: 0.375rem;
}
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 5rem;
}
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #00CC88;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #FFF;
  background-color: rgba(0, 204, 136, 0.9);
  border-radius: 0.375rem;
}
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00CC88;
  padding-right: calc(1.3em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.375rem) center;
  background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #00CC88;
  box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}
.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.3em + 1.5rem);
  background-position: top calc(0.325em + 0.375rem) right calc(0.325em + 0.375rem);
}
.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #00CC88;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 6.875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300CC88' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #00CC88;
  box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}
.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #00CC88;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #00CC88;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00CC88;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #FF3366;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #FFF;
  background-color: rgba(255, 51, 102, 0.9);
  border-radius: 0.375rem;
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FF3366;
  padding-right: calc(1.3em + 1.5rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.325em + 0.375rem) center;
  background-size: calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #FF3366;
  box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}
.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.3em + 1.5rem);
  background-position: top calc(0.325em + 0.375rem) right calc(0.325em + 0.375rem);
}
.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #FF3366;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 6.875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232D3748' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF3366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF3366' stroke='none'/%3e%3c/svg%3e");
  background-position: right 1.25rem center, center right 3.75rem;
  background-size: 16px 12px, calc(0.65em + 0.75rem) calc(0.65em + 0.75rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #FF3366;
  box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}
.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #FF3366;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #FF3366;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FF3366;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}
form {
  -webkit-margin-after: 0;
          margin-block-end: 0;
}
.form-control:focus::-moz-placeholder {
  color: #ABB6C5;
}
.form-control:focus::placeholder {
  color: #ABB6C5;
}
.form-control-muted {
  background-color: #eef0f5;
  border-color: #eef0f5;
}
.form-control-muted:focus {
  background-color: #f1f2f7;
}
.form-control-alt {
  background-color: #FFF;
  border-color: #FFF;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
}
.form-control-alt:focus {
  background-color: #FFF;
  border-color: transparent;
  box-shadow: 0 0 0 2px #5C60F5;
}
.form-control-flush {
  padding: 0;
  border-width: 0;
  background-color: transparent;
  box-shadow: none;
}
.form-control-flush:focus {
  background-color: transparent;
  box-shadow: none;
  border-width: 0;
}
.form-check-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #16192C;
}
textarea[resize=none] {
  resize: none !important;
}
textarea[resize=both] {
  resize: both !important;
}
textarea[resize=vertical] {
  resize: vertical !important;
}
textarea[resize=horizontal] {
  resize: horizontal !important;
}
.textarea-autosize {
  display: grid;
}
.textarea-autosize:after {
  content: attr(data-replicated-value) " ";
  white-space: pre-wrap;
  visibility: hidden;
}
.textarea-autosize > textarea {
  resize: none;
  overflow: hidden;
}
.textarea-autosize > textarea, .textarea-autosize:after {
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;
  grid-area: 1/1/2/2;
}
.form-group-stacked .form-control,
.form-group-stacked .form-select {
  position: relative;
  box-sizing: border-box;
  height: auto;
}
.form-group-stacked .form-control:focus,
.form-group-stacked .form-select:focus {
  z-index: 2;
}
.form-group-stacked > :first-child .form-control,
.form-group-stacked > :first-child .form-select {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-group-stacked > :last-child .form-control,
.form-group-stacked > :last-child .form-select {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-group-stacked > .row {
  margin: 0;
}
.form-group-stacked > .row > :first-child,
.form-group-stacked > .row > :last-child {
  padding: 0;
}
.form-group-stacked > .row > :first-child .form-control,
.form-group-stacked > .row > :first-child .form-select {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.form-group-stacked > .row > :last-child .form-control,
.form-group-stacked > .row > :last-child .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form-dark .form-control {
  color: rgba(255, 255, 255, 0.9);
  background-color: #2d3041;
  border: 1px solid #2d3041;
}
.form-dark .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.form-dark .form-control::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.form-dark .form-control:focus {
  background-color: #222537;
  border: 1px solid #222537;
}
.form-dark .form-control:focus::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.form-dark .form-control:focus::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.form-dark .input-group .input-group-text {
  background-color: #2d3041;
  border-color: #2d3041;
  color: rgba(255, 255, 255, 0.9);
}
.form-dark .input-group:focus-within .input-group-text {
  background-color: #222537;
  border-color: #222537;
}
.form-stacked .form-control {
  position: relative;
  box-sizing: border-box;
}
.form-stacked .form-control:first-of-type {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-stacked .form-control:not(:first-of-type):not(:last-of-type) {
  margin-bottom: -1px;
  border-radius: 0;
}
.form-stacked .form-control:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-stacked .form-control:focus {
  z-index: 2;
}
.input-group {
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08);
  border-radius: 0.375rem;
}
.input-group .form-control {
  box-shadow: none;
}
.input-group:focus-within {
  box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.input-group:focus-within .input-group-text {
  border-color: #5C60F5;
}
.input-group:focus-within .form-control {
  box-shadow: none;
  border-color: #5C60F5;
}
.input-group-text {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.input-group-flush .form-control,
.input-group-flush .input-group-text {
  border: 0 !important;
}
.input-group-inline {
  position: relative;
  border-radius: 0.375rem;
}
.input-group-inline.input-group-sm {
  border-radius: 0.375rem;
}
.input-group-inline.input-group-lg {
  border-radius: 0.5rem;
}
.input-group-inline .input-group-text {
  background: #FFF;
}
.input-group-inline > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0 !important;
  border-left: 0;
  padding-left: 0.25rem;
}
.input-group-inline > :not(:last-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-right: 0 !important;
  border-right: 0;
  padding-right: 0.25rem;
}
.form-switch {
  min-height: 1.5rem;
}
.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0;
}
.form-switch > .form-check-label {
  margin-top: calc((1.5rem - 1.625 * 1em) / 2);
}
.form-check-linethrough .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
}
.form-item-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  display: none;
}
.form-item-check:hover + .form-item .form-item-click {
  border-color: #5C60F5;
}
.form-item-check:checked + .form-item .form-item-click {
  border-color: #5C60F5;
}
.form-item-check[disabled] + .form-item .form-item-click,
.form-item-check:disabled + .form-item .form-item-click {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-color {
  list-style: none;
  padding: 0;
}
.form-color:after, .form-color:before {
  content: "";
  display: table;
}
.form-color:after {
  clear: both;
}
.form-color label {
  width: 2.25rem;
  height: 2.25rem;
  float: left;
  padding: 0.375rem;
  margin-bottom: 0;
  margin-right: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: all 0.2s ease-in-out;
  transform: scale(0.95);
}
@media (prefers-reduced-motion: reduce) {
  .form-color label {
    transition: none;
  }
}
.form-color label:hover {
  cursor: pointer;
  opacity: 1;
}
.form-color label i, .form-color label svg {
  opacity: 0;
  color: rgba(255, 255, 255, 0.9);
}
.form-color input {
  left: -9999px;
  position: absolute;
}
.form-color input:checked + label {
  transform: scale(1.1);
  opacity: 1;
}
.form-color input:checked + label i, .form-color input:checked + label svg {
  opacity: 1;
}
.form-color-wide label {
  height: 1.5rem;
}
.form-color-xl label {
  width: 5rem;
  height: 5rem;
}
.form-color-xl.form-color-wide label {
  width: 5rem;
  height: 3.5rem;
}
.form-color-lg label {
  width: 3.25rem;
  height: 3.25rem;
}
.form-color-sm label {
  width: 1.25rem;
  height: 1.25rem;
}
.was-validated .input-group:valid .form-control,
.was-validated .input-group:valid .form-select,
.was-validated .input-group:valid .input-group-text, .input-group.is-valid .form-control,
.input-group.is-valid .form-select,
.input-group.is-valid .input-group-text {
  border-color: #00CC88;
}
.was-validated .input-group:valid:focus-within, .input-group.is-valid:focus-within {
  border-color: #00CC88;
  box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.25);
}
.was-validated .input-group:invalid .form-control,
.was-validated .input-group:invalid .form-select,
.was-validated .input-group:invalid .input-group-text, .input-group.is-invalid .form-control,
.input-group.is-invalid .form-select,
.input-group.is-invalid .input-group-text {
  border-color: #FF3366;
}
.was-validated .input-group:invalid:focus-within, .input-group.is-invalid:focus-within {
  border-color: #FF3366;
  box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.25);
}
.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem 1.25rem;
  font-size: 1rem;
  color: #525F7F;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #16192C;
  background-color: transparent;
  box-shadow: inset 0 -1px 0 #E7EAF0;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2316192C'%3e%3cpath fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23525F7F'%3e%3cpath fill-rule='evenodd' d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #E7EAF0;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.accordion-header {
  margin-bottom: 0;
}
.accordion-item {
  background-color: transparent;
  border: 1px solid #E7EAF0;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.accordion-body {
  padding: 1.5rem 1.25rem;
}
.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}
.accordion-card .card-header {
  position: relative;
  cursor: pointer;
}
.accordion-card .card-header:after {
  content: "+";
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-family: var(--x-font-sans-serif);
  font-weight: 700;
}
.accordion-card .card-header[aria-expanded=false]:after {
  content: "+";
}
.accordion-card .card-header[aria-expanded=true]:after {
  content: "-";
}
.accordion-card .card-header[aria-expanded=true] .heading {
  color: theme-color("primary");
}
.accordion-spaced > .card {
  border-radius: 0.75rem !important;
}
.accordion-spaced > .card:not(:last-of-type) {
  margin-bottom: 1.5rem;
  border: 1px solid #eceef3;
}
.alert {
  position: relative;
  padding: 0.875rem 1rem;
  margin-bottom: 0;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 600;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.09375rem 1rem;
}
.alert-primary {
  color: #5356dd;
  background-color: #efeffe;
  border-color: #dedffd;
}
.alert-primary .alert-link {
  color: #4245b1;
}
.alert-secondary {
  color: #151516;
  background-color: #fafbfc;
  border-color: #f5f7f9;
}
.alert-secondary .alert-link {
  color: #111112;
}
.alert-tertiary {
  color: #e64e8b;
  background-color: #ffeef5;
  border-color: #ffddeb;
}
.alert-tertiary .alert-link {
  color: #b83e6f;
}
.alert-success {
  color: #00b87a;
  background-color: #e6faf3;
  border-color: #ccf5e7;
}
.alert-success .alert-link {
  color: #009362;
}
.alert-info {
  color: #00bfe6;
  background-color: #e6fbff;
  border-color: #ccf6ff;
}
.alert-info .alert-link {
  color: #0099b8;
}
.alert-warning {
  color: #e67e00;
  background-color: #fff4e6;
  border-color: #ffe8cc;
}
.alert-warning .alert-link {
  color: #b86500;
}
.alert-danger {
  color: #e62e5c;
  background-color: #ffebf0;
  border-color: #ffd6e0;
}
.alert-danger .alert-link {
  color: #b8254a;
}
.alert-white {
  color: #1a1a1a;
  background-color: white;
  border-color: white;
}
.alert-white .alert-link {
  color: #151515;
}
.alert-light {
  color: #1a1a1a;
  background-color: white;
  border-color: white;
}
.alert-light .alert-link {
  color: #151515;
}
.alert-dark {
  color: #141728;
  background-color: #e8e8ea;
  border-color: #d0d1d5;
}
.alert-dark .alert-link {
  color: #101220;
}
.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 1rem;
  font-weight: 600;
  height: 2.875rem;
  width: 2.875rem;
  border-radius: 0.375rem;
}
.avatar img {
  width: 100%;
  border-radius: inherit;
}
.avatar + .avatar {
  margin-left: 0.25rem;
}
.avatar + .avatar-content {
  display: inline-block;
  margin-left: 0.75rem;
}
.avatar-2xl {
  width: 7.5rem;
  height: 7.5rem;
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .avatar-2xl {
    font-size: 2.5rem;
  }
}
.avatar-xl {
  width: 6rem;
  height: 6rem;
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  .avatar-xl {
    font-size: 1.375rem;
  }
}
.avatar-lg {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 1.25rem;
}
.avatar-sm {
  width: 2.25rem;
  height: 2.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}
.avatar-xs {
  width: 1.75rem;
  height: 1.75rem;
  font-size: 0.675rem;
  border-radius: 0.25rem;
}
.avatar-group {
  display: inline-block;
  line-height: 1;
}
.avatar-group .avatar {
  z-index: 1;
  transition: margin 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .avatar-group .avatar {
    transition: none;
  }
}
.avatar-group .avatar:hover {
  z-index: 2;
}
.avatar-group .avatar + .avatar {
  margin-left: -1.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.avatar-group .avatar-sm + .avatar-sm,
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -1rem;
}
.avatar-group:hover .avatar {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.avatar-group:hover .avatar-sm {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.avatar-ungroup-hover:hover .avatar:not(:first-child) {
  margin-left: 0;
}
.badge {
  display: inline-block;
  padding: 0.2rem 0.6rem;
  font-size: 0.75em;
  font-weight: 600;
  line-height: 1;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge {
  line-height: 1rem;
}
.badge-xs {
  padding: 0.1rem 0.5rem;
  font-size: 60%;
}
.badge-md {
  padding: 0.4rem 1rem;
}
.badge-lg {
  padding: 0.6rem 1rem;
  font-size: 1em;
}
.badge-count {
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: middle;
  font-size: 0.75rem;
  font-weight: 600;
}
.badge-count.badge-xs {
  width: 1rem;
  height: 1rem;
  font-size: 0.5rem;
}
.badge-count.badge-md {
  width: 1.5rem;
  height: 1.5rem;
}
.badge-count.badge-lg {
  width: 2rem;
  height: 2rem;
}
.badge-count svg {
  margin: 0;
}
.badge-dot {
  display: inline-flex;
  align-items: center;
  padding: 0;
  background: transparent;
  font-weight: 400;
  color: #525F7F;
}
.badge-dot i {
  display: inline-block;
  vertical-align: middle;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.badge-dot.badge-md i {
  width: 0.5rem;
  height: 0.5rem;
}
.badge-dot.badge-lg i {
  width: 0.625rem;
  height: 0.625rem;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  list-style: none;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.375rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.375rem;
  color: #ABB6C5;
  content: var(--x-breadcrumb-divider, "/") /* rtl: var(--x-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #16192C;
}
.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.3;
  color: #525F7F;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #525F7F;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25), 0 0 0 0 rgba(0, 0, 0, 0);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}
.btn-primary {
  color: #FFF;
  background-color: #5C60F5;
  border-color: #5C60F5;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #FFF;
  background-color: #4a4dc4;
  border-color: #4a4dc4;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #FFF;
  background-color: #4a4dc4;
  border-color: #4a4dc4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(116, 120, 247, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #FFF;
  background-color: #4a4dc4;
  border-color: #4548b8;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(116, 120, 247, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #FFF;
  background-color: #5C60F5;
  border-color: #5C60F5;
}
.btn-secondary {
  color: #000;
  background-color: #CFD6DF;
  border-color: #CFD6DF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #000;
  background-color: #d9dee5;
  border-color: #d4dae2;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #d9dee5;
  border-color: #d4dae2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(176, 182, 190, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #d9dee5;
  border-color: #d4dae2;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(176, 182, 190, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #CFD6DF;
  border-color: #CFD6DF;
}
.btn-tertiary {
  color: #FFF;
  background-color: #FF579A;
  border-color: #FF579A;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-tertiary:hover {
  color: #FFF;
  background-color: #cc467b;
  border-color: #cc467b;
}
.btn-check:focus + .btn-tertiary, .btn-tertiary:focus {
  color: #FFF;
  background-color: #cc467b;
  border-color: #cc467b;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 112, 169, 0.5);
}
.btn-check:checked + .btn-tertiary, .btn-check:active + .btn-tertiary, .btn-tertiary:active, .btn-tertiary.active, .show > .btn-tertiary.dropdown-toggle {
  color: #FFF;
  background-color: #cc467b;
  border-color: #bf4174;
}
.btn-check:checked + .btn-tertiary:focus, .btn-check:active + .btn-tertiary:focus, .btn-tertiary:active:focus, .btn-tertiary.active:focus, .show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 112, 169, 0.5);
}
.btn-tertiary:disabled, .btn-tertiary.disabled {
  color: #FFF;
  background-color: #FF579A;
  border-color: #FF579A;
}
.btn-success {
  color: #FFF;
  background-color: #00CC88;
  border-color: #00CC88;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #FFF;
  background-color: #00a36d;
  border-color: #00a36d;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #FFF;
  background-color: #00a36d;
  border-color: #00a36d;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(38, 212, 154, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #FFF;
  background-color: #00a36d;
  border-color: #009966;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(38, 212, 154, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #FFF;
  background-color: #00CC88;
  border-color: #00CC88;
}
.btn-info {
  color: #000;
  background-color: #00D4FF;
  border-color: #00D4FF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #000;
  background-color: #33ddff;
  border-color: #1ad8ff;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #33ddff;
  border-color: #1ad8ff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 180, 217, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #33ddff;
  border-color: #1ad8ff;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(0, 180, 217, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #00D4FF;
  border-color: #00D4FF;
}
.btn-warning {
  color: #FFF;
  background-color: #FF8C00;
  border-color: #FF8C00;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #FFF;
  background-color: #cc7000;
  border-color: #cc7000;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #FFF;
  background-color: #cc7000;
  border-color: #cc7000;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 157, 38, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #FFF;
  background-color: #cc7000;
  border-color: #bf6900;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 157, 38, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #FFF;
  background-color: #FF8C00;
  border-color: #FF8C00;
}
.btn-danger {
  color: #FFF;
  background-color: #FF3366;
  border-color: #FF3366;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #FFF;
  background-color: #cc2952;
  border-color: #cc2952;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #FFF;
  background-color: #cc2952;
  border-color: #cc2952;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(255, 82, 125, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #FFF;
  background-color: #cc2952;
  border-color: #bf264d;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 82, 125, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #FFF;
  background-color: #FF3366;
  border-color: #FF3366;
}
.btn-white {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-light {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(217, 217, 217, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-dark {
  color: #FFF;
  background-color: #16192C;
  border-color: #16192C;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #FFF;
  background-color: #121423;
  border-color: #121423;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #FFF;
  background-color: #121423;
  border-color: #121423;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(57, 60, 76, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #FFF;
  background-color: #121423;
  border-color: #111321;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(57, 60, 76, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #FFF;
  background-color: #16192C;
  border-color: #16192C;
}
.btn-outline-primary {
  color: #5C60F5;
  border-color: #5C60F5;
}
.btn-outline-primary:hover {
  color: #FFF;
  background-color: #5C60F5;
  border-color: #5C60F5;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #FFF;
  background-color: #5C60F5;
  border-color: #5C60F5;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(92, 96, 245, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #5C60F5;
  background-color: transparent;
}
.btn-outline-secondary {
  color: #CFD6DF;
  border-color: #CFD6DF;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #CFD6DF;
  border-color: #CFD6DF;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 3px rgba(207, 214, 223, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #CFD6DF;
  border-color: #CFD6DF;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(207, 214, 223, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #CFD6DF;
  background-color: transparent;
}
.btn-outline-tertiary {
  color: #FF579A;
  border-color: #FF579A;
}
.btn-outline-tertiary:hover {
  color: #FFF;
  background-color: #FF579A;
  border-color: #FF579A;
}
.btn-check:focus + .btn-outline-tertiary, .btn-outline-tertiary:focus {
  box-shadow: 0 0 0 3px rgba(255, 87, 154, 0.5);
}
.btn-check:checked + .btn-outline-tertiary, .btn-check:active + .btn-outline-tertiary, .btn-outline-tertiary:active, .btn-outline-tertiary.active, .btn-outline-tertiary.dropdown-toggle.show {
  color: #FFF;
  background-color: #FF579A;
  border-color: #FF579A;
}
.btn-check:checked + .btn-outline-tertiary:focus, .btn-check:active + .btn-outline-tertiary:focus, .btn-outline-tertiary:active:focus, .btn-outline-tertiary.active:focus, .btn-outline-tertiary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 87, 154, 0.5);
}
.btn-outline-tertiary:disabled, .btn-outline-tertiary.disabled {
  color: #FF579A;
  background-color: transparent;
}
.btn-outline-success {
  color: #00CC88;
  border-color: #00CC88;
}
.btn-outline-success:hover {
  color: #FFF;
  background-color: #00CC88;
  border-color: #00CC88;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 3px rgba(0, 204, 136, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #FFF;
  background-color: #00CC88;
  border-color: #00CC88;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(0, 204, 136, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #00CC88;
  background-color: transparent;
}
.btn-outline-info {
  color: #00D4FF;
  border-color: #00D4FF;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #00D4FF;
  border-color: #00D4FF;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 3px rgba(0, 212, 255, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #00D4FF;
  border-color: #00D4FF;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(0, 212, 255, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #00D4FF;
  background-color: transparent;
}
.btn-outline-warning {
  color: #FF8C00;
  border-color: #FF8C00;
}
.btn-outline-warning:hover {
  color: #FFF;
  background-color: #FF8C00;
  border-color: #FF8C00;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 3px rgba(255, 140, 0, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #FFF;
  background-color: #FF8C00;
  border-color: #FF8C00;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 140, 0, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #FF8C00;
  background-color: transparent;
}
.btn-outline-danger {
  color: #FF3366;
  border-color: #FF3366;
}
.btn-outline-danger:hover {
  color: #FFF;
  background-color: #FF3366;
  border-color: #FF3366;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 3px rgba(255, 51, 102, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #FFF;
  background-color: #FF3366;
  border-color: #FF3366;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 51, 102, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #FF3366;
  background-color: transparent;
}
.btn-outline-white {
  color: #FFF;
  border-color: #FFF;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #FFF;
  background-color: transparent;
}
.btn-outline-light {
  color: #FFF;
  border-color: #FFF;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #FFF;
  border-color: #FFF;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(255, 255, 255, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #FFF;
  background-color: transparent;
}
.btn-outline-dark {
  color: #16192C;
  border-color: #16192C;
}
.btn-outline-dark:hover {
  color: #FFF;
  background-color: #16192C;
  border-color: #16192C;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 3px rgba(22, 25, 44, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #FFF;
  background-color: #16192C;
  border-color: #16192C;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(22, 25, 44, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #16192C;
  background-color: transparent;
}
.btn-link {
  font-weight: 400;
  color: #5C60F5;
  text-decoration: none;
}
.btn-link:hover {
  color: #4e52d0;
  text-decoration: none;
}
.btn-link:focus {
  text-decoration: none;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6B7B93;
}
.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}
.btn-sm, .btn-group-sm > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-radius: 0.375rem;
}
.btn-neutral {
  color: #16192C;
  background-color: #FFF;
  border-color: #E7EAF0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-neutral:hover {
  color: #16192C;
  background-color: #E7EAF0;
  border-color: #E7EAF0;
}
.btn-check:focus + .btn-neutral, .btn-neutral:focus {
  color: #16192C;
  background-color: #E7EAF0;
  border-color: #E7EAF0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(200, 203, 211, 0.5);
}
.btn-check:checked + .btn-neutral, .btn-check:active + .btn-neutral, .btn-neutral:active, .btn-neutral.active, .show > .btn-neutral.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: #e9ecf2;
}
.btn-check:checked + .btn-neutral:focus, .btn-check:active + .btn-neutral:focus, .btn-neutral:active:focus, .btn-neutral.active:focus, .show > .btn-neutral.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 3px rgba(200, 203, 211, 0.5);
}
.btn-neutral:disabled, .btn-neutral.disabled {
  color: #000;
  background-color: #FFF;
  border-color: #E7EAF0;
}
.btn-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.875rem;
  height: 2.875rem;
  padding: 0;
}
.btn-square.btn-xs {
  width: 1.75rem;
  height: 1.75rem;
}
.btn-square.btn-sm, .btn-group-sm > .btn-square.btn {
  width: 2.25rem;
  height: 2.25rem;
}
.btn-square.btn-lg, .btn-group-lg > .btn-square.btn {
  width: 3.5rem;
  height: 3.5rem;
}
.btn-square.btn-xl {
  width: 6rem;
  height: 6rem;
}
.btn-square.btn-2xl {
  width: 7.5rem;
  height: 7.5rem;
}
.btn-app {
  background: #000;
  color: #FFF;
  position: relative;
  padding-left: 4.5rem;
  padding-right: 1.5rem;
  text-align: left;
}
.btn-app i, .btn-app svg {
  width: 1em;
  font-size: 2.5rem;
  position: absolute;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
}
.btn-app .btn-text {
  display: block;
  font-size: 0.75rem;
  line-height: 0.75rem;
  margin-bottom: 0.125rem;
}
.btn-app .btn-brand {
  display: block;
  text-align: left;
  font-size: 21px;
  line-height: 21px;
}
.btn-app:hover {
  background-color: #5659e4;
  color: #FFF;
}
.btn-animated {
  position: relative;
  overflow: hidden;
}
.btn-animated .btn-inner-visible {
  position: relative;
}
.btn-animated .btn-inner-hidden {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.btn-animated-x .btn-inner-visible {
  right: 0;
  transition: right 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-visible {
    transition: none;
  }
}
.btn-animated-x .btn-inner-hidden {
  right: -100%;
  transition: right 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-x .btn-inner-hidden {
    transition: none;
  }
}
.btn-animated-x:hover .btn-inner-hidden {
  right: 0;
}
.btn-animated-x:hover .btn-inner-visible {
  right: 150%;
}
.btn-animated-y .btn-inner-visible {
  top: 0;
  transition: top 0.3s ease 0s;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-visible {
    transition: none;
  }
}
.btn-animated-y .btn-inner-hidden {
  left: 0;
  top: -100%;
  transition: top 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .btn-animated-y .btn-inner-hidden {
    transition: none;
  }
}
.btn-animated-y:hover .btn-inner-hidden {
  top: 50%;
}
.btn-animated-y:hover .btn-inner-visible {
  top: 100px;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}
.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}
/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #FFF;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #FFF;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFF;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #FFF;
  text-align: center;
}
.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: currentColor;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='currentColor'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: currentColor;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(92, 96, 245, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 12rem;
  padding: 0.5rem 0rem;
  margin: 0;
  font-size: 0.875rem;
  color: #525F7F;
  text-align: left;
  list-style: none;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #f0f2f6;
  border-radius: 0.75rem;
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}
.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}
.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}
.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #f2f3f7;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #16192C;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: theme-color("primary");
  background-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  color: theme-color("primary");
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #8898A9;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6B7B93;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #16192C;
}
.dropdown-menu-dark {
  color: #CFD6DF;
  background-color: #2D3748;
  border-color: #f0f2f6;
}
.dropdown-menu-dark .dropdown-item {
  color: #CFD6DF;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: theme-color("primary");
  background-color: transparent;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #8898A9;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: #f2f3f7;
}
.dropdown-menu-dark .dropdown-item-text {
  color: #CFD6DF;
}
.dropdown-menu-dark .dropdown-header {
  color: #8898A9;
}
.dropdown,
.dropup,
.dropright,
.dropleft {
  display: inline-block;
}
.dropdown-heading {
  color: #16192C;
  font-size: 1rem;
  font-weight: 500;
}
.dropdown-helper {
  color: #6B7B93;
}
.dropdown-group {
  display: block;
}
.dropdown-group:hover .dropdown-heading {
  color: #5C60F5;
}
.dropdown-img-left {
  position: relative;
  display: flex;
  min-height: 200px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.625rem 1.75rem;
  background: no-repeat center center/cover;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.dropdown-img-left.dropdown-img-mask:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(92, 96, 245, 0.7);
  border-radius: inherit;
}
.dropdown-secondary {
  background-color: #F5F9FC;
}
.dropdown-body-left {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.dropdown-body-right {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.dropdown-menu-fluid {
  width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
  left: var(--x-gutter-x, 1.5rem) !important;
}
.dropdown-menu-fluid[data-bs-popper] {
  left: var(--x-gutter-x, 1.5rem);
}
@media (min-width: 576px) {
  .dropdown-menu-sm-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-sm-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-md-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-lg-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-xl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-fluid {
    width: calc(100% - var(--x-gutter-x, 1.5rem) * 2);
    left: var(--x-gutter-x, 1.5rem) !important;
  }
  .dropdown-menu-xxl-fluid[data-bs-popper] {
    left: var(--x-gutter-x, 1.5rem);
  }
}
.dropdown-submenu .dropdown-menu {
  min-width: 16rem;
  margin-left: 1rem;
}
.dropdown-submenu .dropdown-menu:before {
  content: "";
  border-left: 1.5rem solid transparent;
  position: absolute;
  left: -1rem;
  top: 0;
  height: 100%;
}
.dropdown-body {
  padding: 2rem 2.5rem;
}
.delimiter {
  border-right: 1px solid #E7EAF0;
}
@media (min-width: 768px) {
  .dropdown-menu-sm {
    min-width: 10rem !important;
  }
  .dropdown-menu-sm.dropdown-menu-arrow.dropdown-menu-right:before {
    right: 2rem;
    left: auto;
  }

  .dropdown-menu-md {
    min-width: 25rem !important;
  }

  .dropdown-menu-lg {
    min-width: 30rem !important;
  }

  .dropdown-menu-xl {
    min-width: 40rem !important;
  }

  .dropdown-menu-2xl {
    min-width: 50rem !important;
  }

  .dropdown-menu-centered {
    left: 50% !important;
    transform: translateX(-50%) translateY(-3px) scale(0.96);
  }
}
.dropdown-animate > .dropdown-menu {
  opacity: 0;
  position: absolute;
  display: block;
  pointer-events: none;
}
.dropdown-animate:not([data-toggle=hover]) .dropdown-menu.show {
  pointer-events: auto;
  -webkit-animation: show-dropdown 0.3s ease forwards;
          animation: show-dropdown 0.3s ease forwards;
}
.dropdown-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
  display: block;
  -webkit-animation: hide-dropdown 0.3s ease backwards;
          animation: hide-dropdown 0.3s ease backwards;
}
.dropdown-animate[data-toggle=hover] > .dropdown-menu {
  display: block;
  margin: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dropdown-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
.dropdown-animate[data-toggle=hover]:hover > .dropdown-menu {
  display: block;
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  transform: translateX(0) translateY(-6px) scale(1);
}
@media (min-width: 576px) {
  .dropdown-sm-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }

  .dropdown-sm-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    -webkit-animation: show-dropdown 0.3s ease forwards;
            animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-sm-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    -webkit-animation: hide-dropdown 0.3s ease backwards;
            animation: hide-dropdown 0.3s ease backwards;
  }

  .dropdown-sm-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .dropdown-sm-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 576px) {
  .dropdown-sm-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 768px) {
  .dropdown-md-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }

  .dropdown-md-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    -webkit-animation: show-dropdown 0.3s ease forwards;
            animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-md-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    -webkit-animation: hide-dropdown 0.3s ease backwards;
            animation: hide-dropdown 0.3s ease backwards;
  }

  .dropdown-md-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .dropdown-md-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 768px) {
  .dropdown-md-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 992px) {
  .dropdown-lg-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }

  .dropdown-lg-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    -webkit-animation: show-dropdown 0.3s ease forwards;
            animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-lg-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    -webkit-animation: hide-dropdown 0.3s ease backwards;
            animation: hide-dropdown 0.3s ease backwards;
  }

  .dropdown-lg-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .dropdown-lg-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 992px) {
  .dropdown-lg-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 1200px) {
  .dropdown-xl-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }

  .dropdown-xl-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    -webkit-animation: show-dropdown 0.3s ease forwards;
            animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-xl-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    -webkit-animation: hide-dropdown 0.3s ease backwards;
            animation: hide-dropdown 0.3s ease backwards;
  }

  .dropdown-xl-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .dropdown-xl-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 1200px) {
  .dropdown-xl-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@media (min-width: 1400px) {
  .dropdown-xxl-animate > .dropdown-menu {
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
  }

  .dropdown-xxl-animate:not([data-toggle=hover]) .dropdown-menu.show {
    pointer-events: auto;
    -webkit-animation: show-dropdown 0.3s ease forwards;
            animation: show-dropdown 0.3s ease forwards;
  }
  .dropdown-xxl-animate:not([data-toggle=hover]) .dropdown-menu:not(.show) {
    display: block;
    -webkit-animation: hide-dropdown 0.3s ease backwards;
            animation: hide-dropdown 0.3s ease backwards;
  }

  .dropdown-xxl-animate[data-toggle=hover] > .dropdown-menu {
    display: block;
    margin: 0;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .dropdown-xxl-animate[data-toggle=hover] > .dropdown-menu {
    transition: none;
  }
}
@media (min-width: 1400px) {
  .dropdown-xxl-animate[data-toggle=hover]:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0) translateY(-6px) scale(1);
  }
}
@-webkit-keyframes show-dropdown {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(-3px) scale(0.97);
    transition: all 0.3s ease;
  }
  100% {
    transform: translateX(0) translateY(-3px) scale(1);
    opacity: 1;
  }
}
@keyframes show-dropdown {
  0% {
    opacity: 0;
    transform: translateX(0) translateY(-3px) scale(0.97);
    transition: all 0.3s ease;
  }
  100% {
    transform: translateX(0) translateY(-3px) scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes hide-dropdown {
  from {
    opacity: 1;
    transform: translateX(0) translateY(-3px) scale(1);
    transition: all 0.3s ease;
  }
  to {
    opacity: 0;
    transform: translateX(0) translateY(-3px) scale(0.97);
  }
}
@keyframes hide-dropdown {
  from {
    opacity: 1;
    transform: translateX(0) translateY(-3px) scale(1);
    transition: all 0.3s ease;
  }
  to {
    opacity: 0;
    transform: translateX(0) translateY(-3px) scale(0.97);
  }
}
.dropdown-menu .dropdown-menu {
  left: 100%;
  margin: 0;
  right: auto;
  top: 0;
}
.dropdown-submenu {
  display: block;
  position: relative;
}
.dropdown-submenu > .dropdown-menu::after {
  border: 0 !important;
}
.dropdown-submenu > .dropdown-item::after,
.dropdown-submenu > .list-group-item::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropdown-submenu > .dropdown-item:empty::after,
.dropdown-submenu > .list-group-item:empty::after {
  margin-left: 0;
}
.dropdown-submenu > .dropdown-item:after,
.dropdown-submenu > .list-group-item:after {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-left: 0;
  font-size: 0.75rem;
  transform: translateY(-50%);
}
.dropdown-submenu[data-toggle=hover] > .dropdown-menu {
  left: 100%;
  transform: translateX(0) translateY(-3px) scale(0.97);
}
.dropdown-submenu[data-toggle=hover]:hover > .dropdown-menu {
  transform: translateX(0) translateY(-3px) scale(1);
}
@media (max-width: 767.98px) {
  .dropdown-submenu .dropdown-menu {
    padding: 0;
    box-shadow: none;
    top: 0;
  }
  .dropdown-submenu .dropdown-item {
    padding-left: 3rem;
  }
}
.frame-laptop {
  position: relative;
}
.frame-laptop .frame-inner {
  position: absolute;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 70%;
  height: 78%;
  margin: 2.5% 15% 0 15%;
}
.frame-iphone {
  position: relative;
  z-index: 100;
}
.frame-iphone .frame-inner {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 92%;
  height: 96%;
  margin: 4% 4% 0 4%;
  border-radius: 5%;
}
.frame-stack {
  position: relative;
}
.frame-stack .frame {
  position: absolute;
}
.frame-stack-iphone-iphone {
  padding-bottom: 130.250482%;
}
.frame-stack-iphone-iphone > .frame-iphone:first-child {
  bottom: 0;
  left: 0;
  width: 65.5260116%;
  z-index: 1;
}
.frame-stack-iphone-iphone > .frame-iphone:last-child {
  top: 0;
  right: 0;
  width: 72.8323699%;
}
.frame-stack-iphone-laptop,
.frame-stack-laptop-iphone {
  padding-bottom: 62.4260355%;
}
.frame-stack-iphone-laptop > .frame-laptop,
.frame-stack-laptop-iphone > .frame-laptop {
  width: 91.7159763%;
}
.frame-stack-iphone-laptop > .frame-iphone,
.frame-stack-laptop-iphone > .frame-iphone {
  width: 27.9585799%;
  z-index: 1;
}
.frame-stack-laptop-iphone > .frame-laptop {
  top: 0;
  left: 0;
}
.frame-stack-iphone-laptop > .frame-laptop {
  top: 0;
  right: 0;
}
.frame-stack-laptop-iphone > .frame-iphone {
  bottom: 0;
  right: 0;
}
.frame-stack-iphone-laptop > .frame-iphone {
  bottom: 0;
  left: 0;
}
.icon {
  font-size: 1.7142857143rem;
  line-height: 1;
}
.icon svg {
  width: 1em;
  height: 1em;
}
.icon + .icon-text {
  padding-left: 1rem;
  width: calc(100% - 3rem - 1);
}
.icon-xl {
  font-size: 2.8571428571rem;
}
.icon-xl + .icon-text {
  width: calc(100% - 5rem - 1);
}
.icon-lg {
  font-size: 2.2857142857rem;
}
.icon-lg + .icon-text {
  width: calc(100% - 4rem - 1);
}
.icon-sm {
  font-size: 1.1428571429rem;
}
.icon-sm + .icon-text {
  width: calc(100% - 2rem - 1);
}
.icon-xs {
  font-size: 0.7142857143rem;
}
.icon-xs + .icon-text {
  width: calc(100% - 1.25rem - 1);
}
.icon-shape {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 0.375rem;
  width: 3rem;
  height: 3rem;
}
.icon-shape.icon-xl {
  width: 5rem;
  height: 5rem;
}
.icon-shape.icon-lg {
  width: 4rem;
  height: 4rem;
}
.icon-shape.icon-sm {
  width: 2rem;
  height: 2rem;
}
.icon-shape.icon-xs {
  width: 1.25rem;
  height: 1.25rem;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.375rem;
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.list-group-item-action {
  width: 100%;
  color: #525F7F;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #525F7F;
  text-decoration: none;
  background-color: #F5F9FC;
}
.list-group-item-action:active {
  color: #525F7F;
  background-color: #E7EAF0;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 1.125rem 1.5rem;
  color: #16192C;
  background-color: transparent;
  border: 1px solid #E7EAF0;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6B7B93;
  pointer-events: none;
  background-color: transparent;
}
.list-group-item.active {
  z-index: 2;
  color: #525F7F;
  background-color: #F5F9FC;
  border-color: #E7EAF0;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.375rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #373a93;
  background-color: #dedffd;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #373a93;
  background-color: #c8c9e4;
}
.list-group-item-primary.list-group-item-action.active {
  color: #FFF;
  background-color: #373a93;
  border-color: #373a93;
}
.list-group-item-secondary {
  color: #7c8086;
  background-color: #f5f7f9;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #7c8086;
  background-color: #dddee0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #FFF;
  background-color: #7c8086;
  border-color: #7c8086;
}
.list-group-item-tertiary {
  color: #99345c;
  background-color: #ffddeb;
}
.list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
  color: #99345c;
  background-color: #e6c7d4;
}
.list-group-item-tertiary.list-group-item-action.active {
  color: #FFF;
  background-color: #99345c;
  border-color: #99345c;
}
.list-group-item-success {
  color: #007a52;
  background-color: #ccf5e7;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #007a52;
  background-color: #b8ddd0;
}
.list-group-item-success.list-group-item-action.active {
  color: #FFF;
  background-color: #007a52;
  border-color: #007a52;
}
.list-group-item-info {
  color: #007f99;
  background-color: #ccf6ff;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #007f99;
  background-color: #b8dde6;
}
.list-group-item-info.list-group-item-action.active {
  color: #FFF;
  background-color: #007f99;
  border-color: #007f99;
}
.list-group-item-warning {
  color: #995400;
  background-color: #ffe8cc;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #995400;
  background-color: #e6d1b8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #FFF;
  background-color: #995400;
  border-color: #995400;
}
.list-group-item-danger {
  color: #991f3d;
  background-color: #ffd6e0;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #991f3d;
  background-color: #e6c1ca;
}
.list-group-item-danger.list-group-item-action.active {
  color: #FFF;
  background-color: #991f3d;
  border-color: #991f3d;
}
.list-group-item-white {
  color: #999999;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #999999;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #FFF;
  background-color: #999999;
  border-color: #999999;
}
.list-group-item-light {
  color: #999999;
  background-color: white;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #999999;
  background-color: #e6e6e6;
}
.list-group-item-light.list-group-item-action.active {
  color: #FFF;
  background-color: #999999;
  border-color: #999999;
}
.list-group-item-dark {
  color: #0d0f1a;
  background-color: #d0d1d5;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0d0f1a;
  background-color: #bbbcc0;
}
.list-group-item-dark.list-group-item-action.active {
  color: #FFF;
  background-color: #0d0f1a;
  border-color: #0d0f1a;
}
.list-group {
  list-style-type: none;
}
.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}
.list-group-borderless > .list-group-item {
  border: 0;
}
.list-group-space .list-group-item {
  margin-bottom: 1.5rem;
  border-radius: 0.375rem;
}
.list-group-item {
  font-size: 0.875rem;
}
.list-group-img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  vertical-align: top;
  margin: -0.1rem 1.2rem 0 -0.2rem;
}
.list-group-content {
  flex: 1;
  min-width: 0;
}
.list-group-content p {
  line-height: 1.5;
  margin: 0.2rem 0 0;
}
.list-group-heading {
  color: #2D3748;
}
.list-group-heading > small, .list-group-heading > .small {
  float: right;
  color: #8898A9;
  font-weight: 500;
}
.list-group-sm .list-group-item {
  padding: 0.675rem 1.25rem;
  font-size: 0.875rem;
}
.list-group-emphasized .list-group-item {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .list-group-emphasized .list-group-item {
    transition: none;
  }
}
.list-group-emphasized .list-group-item.active {
  z-index: 11;
  transform: scale(1.05);
  background-color: #F5F9FC;
  box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
  border-radius: 0.375rem;
}
.list-group-emphasized .list-group-item.active .media a {
  color: theme-color("primary");
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #E7EAF0;
  border-radius: 0.75rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.4;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid #E7EAF0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}
.modal-header .btn-close {
  padding: 0.75rem 0.75rem;
  margin: -0.75rem -0.75rem -0.75rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.625;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #E7EAF0;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}
.modal-footer > * {
  margin: 0.5rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.modal-content {
  border: 0;
  border-radius: 0.75rem;
}
.modal-fluid .modal-dialog {
  margin-top: 0;
  margin-bottom: 0;
}
.modal-fluid .modal-content {
  border-radius: 0;
}
.modal-open {
  max-height: 100vh;
}
.modal-dialog-aside {
  position: absolute;
  margin: 0;
}
.modal-primary .modal-title {
  color: #FFF;
}
.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-primary .modal-content {
  background-color: #5C60F5;
  color: #FFF;
}
.modal-primary .modal-content .heading {
  color: #FFF;
}
.modal-primary .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-secondary .modal-title {
  color: #000;
}
.modal-secondary .modal-header,
.modal-secondary .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-secondary .modal-content {
  background-color: #CFD6DF;
  color: #000;
}
.modal-secondary .modal-content .heading {
  color: #000;
}
.modal-secondary .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-tertiary .modal-title {
  color: #FFF;
}
.modal-tertiary .modal-header,
.modal-tertiary .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-tertiary .modal-content {
  background-color: #FF579A;
  color: #FFF;
}
.modal-tertiary .modal-content .heading {
  color: #FFF;
}
.modal-tertiary .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-success .modal-title {
  color: #FFF;
}
.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-success .modal-content {
  background-color: #00CC88;
  color: #FFF;
}
.modal-success .modal-content .heading {
  color: #FFF;
}
.modal-success .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-info .modal-title {
  color: #000;
}
.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-info .modal-content {
  background-color: #00D4FF;
  color: #000;
}
.modal-info .modal-content .heading {
  color: #000;
}
.modal-info .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-warning .modal-title {
  color: #FFF;
}
.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-warning .modal-content {
  background-color: #FF8C00;
  color: #FFF;
}
.modal-warning .modal-content .heading {
  color: #FFF;
}
.modal-warning .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-danger .modal-title {
  color: #FFF;
}
.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-danger .modal-content {
  background-color: #FF3366;
  color: #FFF;
}
.modal-danger .modal-content .heading {
  color: #FFF;
}
.modal-danger .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-white .modal-title {
  color: #000;
}
.modal-white .modal-header,
.modal-white .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-white .modal-content {
  background-color: #FFF;
  color: #000;
}
.modal-white .modal-content .heading {
  color: #000;
}
.modal-white .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-light .modal-title {
  color: #000;
}
.modal-light .modal-header,
.modal-light .modal-footer {
  border-color: rgba(0, 0, 0, 0.075);
}
.modal-light .modal-content {
  background-color: #FFF;
  color: #000;
}
.modal-light .modal-content .heading {
  color: #000;
}
.modal-light .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-dark .modal-title {
  color: #FFF;
}
.modal-dark .modal-header,
.modal-dark .modal-footer {
  border-color: rgba(255, 255, 255, 0.075);
}
.modal-dark .modal-content {
  background-color: #16192C;
  color: #FFF;
}
.modal-dark .modal-content .heading {
  color: #FFF;
}
.modal-dark .close > span:not(.sr-only) {
  color: #FFF;
}
.modal-vertical {
  height: calc(100% - 0.5rem);
  max-width: 350px;
  margin: 0.5rem 0;
}
.modal-vertical.modal-lg {
  max-width: 450px;
}
.modal-vertical .modal-content {
  height: inherit;
  border-width: 0;
  border-radius: 0.75rem 0 0 0.75rem;
}
.modal-vertical .modal-body {
  height: inherit;
  overflow-y: auto;
}
.modal.fade .modal-dialog-vertical {
  transform: translateX(-100%);
}
.modal.show .modal-dialog-vertical {
  transform: translateX(0);
}
.modal.fixed-right {
  padding-right: 0 !important;
}
.modal.fixed-right .modal-vertical {
  margin-left: auto;
}
.modal.fixed-right.fade .modal-vertical {
  transform: translateX(100%);
}
.modal.fixed-right.show .modal-vertical {
  transform: translateX(0);
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #FFF;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.4;
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
}
.offcanvas-header .btn-close {
  padding: 0.75rem 0.75rem;
  margin-top: -0.75rem;
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
}
.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.625;
}
.offcanvas-body {
  flex-grow: 1;
  padding: 1.5rem 1.5rem;
  overflow-y: auto;
}
.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid #E7EAF0;
  transform: translateX(-100%);
}
.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid #E7EAF0;
  transform: translateX(100%);
}
.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid #E7EAF0;
  transform: translateY(-100%);
}
.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid #E7EAF0;
  transform: translateY(100%);
}
.offcanvas.show {
  transform: none;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 0;
  font-size: 1rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}
.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.375rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 3px;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.navbar-nav-scroll {
  max-height: var(--x-scroll-height, 75vh);
  overflow-y: auto;
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}
.navbar-light .navbar-brand {
  color: #16192C;
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: #16192C;
}
.navbar-light .navbar-nav .nav-link {
  color: #16192C;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #495392;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: #8898A9;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #16192C;
}
.navbar-light .navbar-toggler {
  color: #16192C;
  border-color: transparent;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%236B7B93' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: #16192C;
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #16192C;
}
.navbar-dark .navbar-brand {
  color: #FFF;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #FFF;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.85);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #FFF;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.85);
  border-color: transparent;
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23E7EAF0' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.85);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #FFF;
}
.navbar {
  position: relative;
  z-index: 100;
}
.navbar [class^=container] {
  position: relative;
}
.navbar-nav .nav-link {
  font-size: 0.925rem;
  font-weight: 500;
  letter-spacing: 0;
  transition: all 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-nav .nav-link {
    transition: none;
  }
}
.navbar-text {
  font-size: 0.925rem;
}
.navbar-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.navbar-img-brand {
  font-size: initial;
}
.navbar-border.navbar-dark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.navbar-border.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.navbar-border.navbar-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04) !important;
}
.navbar-border.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.navbar-dark .border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.navbar-light .border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.navbar-top {
  position: relative;
  display: flex;
  align-items: center;
}
.navbar-top .navbar-nav {
  flex-direction: row;
}
.navbar-top .navbar-nav .nav-item:last-child {
  margin-right: 0;
}
.navbar-top .navbar-nav .nav-link {
  padding: 0.5rem 0.5rem;
}
.navbar-collapse:before {
  content: "";
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0.75rem -1rem;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-collapse:before {
    transition: none;
  }
}
.navbar-collapse.show:before, .navbar-collapse.collapsing:before {
  opacity: 1;
}
.navbar-light .navbar-collapse::before {
  border-top-color: rgba(22, 25, 44, 0.1);
}
.navbar-light .collapse .nav:before {
  border-color: rgba(22, 25, 44, 0.1);
}
.navbar-dark .navbar-collapse::before {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.navbar-dark .collapse .nav:before {
  border-color: rgba(255, 255, 255, 0.2);
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-sm .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-sm .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-sm .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-md .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-md .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-md .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-lg .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-lg .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-lg .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-xl .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-xl .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-xl .navbar-collapse:before {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-brand img {
    height: 40px;
  }
  .navbar-expand-xxl .navbar-brand.dropdown-toggle:after {
    display: none;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0;
  }
  .navbar-expand-xxl .navbar-collapse .collapse-header {
    display: none;
  }
  .navbar-expand-xxl .navbar-collapse:before {
    display: none;
  }
}
.navbar-expand .navbar-brand img {
  height: 40px;
}
.navbar-expand .navbar-brand.dropdown-toggle:after {
  display: none;
}
.navbar-expand .navbar-nav .nav-link {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0;
}
.navbar-expand .navbar-collapse .collapse-header {
  display: none;
}
.navbar-expand .navbar-collapse:before {
  display: none;
}
.navbar-toggler-icon {
  width: 1.25em;
  height: 1.25em;
}
.navbar-search .input-group {
  border-radius: 0.5rem;
  border: 0 solid;
  transition: background-color 0.4s linear;
  transition-delay: 0.2s;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-search .input-group {
    transition: none;
  }
}
.navbar-search .input-group .input-group-text {
  background-color: transparent;
  padding-left: 1rem;
  border: 0;
}
.navbar-search .form-control {
  width: 250px;
  background-color: transparent;
  border: 0;
  transition: all 0.2s cubic-bezier(0.77, 0, 0.2, 2.25);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-search .form-control {
    transition: none;
  }
}
.navbar-search .focused .input-group .form-control {
  width: 380px;
}
.navbar-search .close {
  display: none;
}
.navbar-search-dark .input-group {
  background-color: #05060a;
  border-color: #05060a;
}
.navbar-search-dark .input-group-text {
  color: rgba(255, 255, 255, 0.6);
}
.navbar-search-dark .form-control {
  color: rgba(255, 255, 255, 0.9);
}
.navbar-search-dark .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.navbar-search-dark .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.navbar-search-dark .focused .input-group {
  background-color: black;
  border-color: black;
}
.navbar-search-light .input-group {
  background-color: #f2f2f2;
  border-color: rgba(0, 0, 0, 0.6);
}
.navbar-search-light .input-group-text {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-search-light .form-control {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-search-light .form-control::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-search-light .form-control::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
.navbar-search-light .focused .input-group {
  background-color: #e6e6e6;
  border-color: rgba(0, 0, 0, 0.9);
}
.navbar-vertical .navbar-nav {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.navbar-vertical .navbar-nav .nav-link {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.navbar-vertical .navbar-nav .nav-link.active {
  position: relative;
}
.navbar-vertical .navbar-nav .nav-link i,
.navbar-vertical .navbar-nav .nav-link svg {
  min-width: 1.875rem;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
  border: none;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
  margin-left: 0.5rem;
}
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  display: inline-block;
  content: "";
  background-repeat: no-repeat;
  background-size: 0.875rem 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: auto;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
    transition: none;
  }
}
.navbar-vertical .navbar-nav .nav-link[data-bs-toggle=collapse][aria-expanded=true]:after {
  color: theme-color("primary");
  transform: rotate(90deg);
}
.navbar-vertical .navbar-nav > .nav-item {
  margin-top: 2px;
}
.navbar-vertical .navbar-nav .nav-sm .nav-link {
  font-size: 0.875rem;
}
.navbar-vertical .navbar-nav .nav .nav-link {
  padding-top: 0.4285714286rem;
  padding-bottom: 0.4285714286rem;
  padding-left: 3.375rem;
  font-weight: 400;
}
.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 2.8125rem;
}
.navbar-vertical.navbar-light {
  background-color: #FFF;
  border-color: #E7EAF0;
}
.navbar-vertical.navbar-light .navbar-nav .nav-link i, .navbar-vertical.navbar-light .navbar-nav .nav-link svg {
  color: #6B7B93;
}
.navbar-vertical.navbar-light .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='%2316192C' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.navbar-vertical.navbar-light .navbar-nav > .nav-item > .nav-link:hover {
  background-color: #F5F9FC;
}
.navbar-vertical.navbar-light .navbar-nav > .nav-item > .nav-link.active {
  background-color: #F5F9FC;
}
.navbar-vertical.navbar-light .nav-link-text,
.navbar-vertical.navbar-light .navbar-heading {
  color: #16192C;
}
.navbar-vertical.navbar-dark {
  background-color: #16192C;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-vertical.navbar-dark .navbar-nav .nav-link i, .navbar-vertical.navbar-dark .navbar-nav .nav-link svg {
  color: #F5F9FC;
}
.navbar-vertical.navbar-dark .navbar-nav .nav-link[data-bs-toggle=collapse]:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 16' fill='rgba(255, 255, 255, 0.85)' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}
.navbar-vertical.navbar-dark .navbar-nav > .nav-item > .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-vertical.navbar-dark .navbar-nav > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar-vertical.navbar-dark .nav-link-text,
.navbar-vertical.navbar-dark .navbar-heading {
  color: rgba(255, 255, 255, 0.85);
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm {
    transition: none;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm .nav-link-text,
.navbar-vertical.navbar-expand-sm .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 576px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-sm .nav-link-text,
.navbar-vertical.navbar-expand-sm .navbar-heading {
    transition: none;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-sm.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-sm.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-sm.show .nav-link-text,
.navbar-vertical.navbar-expand-sm.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-sm.show + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-sm.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-sm.hide + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md {
    transition: none;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .nav-link-text,
.navbar-vertical.navbar-expand-md .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-md .nav-link-text,
.navbar-vertical.navbar-expand-md .navbar-heading {
    transition: none;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-md.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-md.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-md.show .nav-link-text,
.navbar-vertical.navbar-expand-md.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-md.show + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-md.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-md.hide + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg {
    transition: none;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg .nav-link-text,
.navbar-vertical.navbar-expand-lg .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 992px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-lg .nav-link-text,
.navbar-vertical.navbar-expand-lg .navbar-heading {
    transition: none;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-lg.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-lg.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-lg.show .nav-link-text,
.navbar-vertical.navbar-expand-lg.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-lg.show + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-lg.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-lg.hide + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl {
    transition: none;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl .nav-link-text,
.navbar-vertical.navbar-expand-xl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1200px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xl .nav-link-text,
.navbar-vertical.navbar-expand-xl .navbar-heading {
    transition: none;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-xl.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-xl.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-xl.show .nav-link-text,
.navbar-vertical.navbar-expand-xl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-xl.show + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-xl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-xl.hide + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    display: block;
    width: 100%;
    max-width: 270px;
    overflow-y: auto;
    z-index: 1000;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
}
@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl {
    transition: none;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl .nav-link-text,
.navbar-vertical.navbar-expand-xxl .navbar-heading {
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }
}
@media (min-width: 1400px) and (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand-xxl .nav-link-text,
.navbar-vertical.navbar-expand-xxl .navbar-heading {
    transition: none;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.show {
    max-width: 270px;
    border-radius: 0;
  }
  .navbar-vertical.navbar-expand-xxl.show.navbar-lg {
    max-width: 320px;
  }
  .navbar-vertical.navbar-expand-xxl.show.navbar-xl {
    max-width: 370px;
  }
  .navbar-vertical.navbar-expand-xxl.show .nav-link-text,
.navbar-vertical.navbar-expand-xxl.show .navbar-heading {
    opacity: 1;
    pointer-events: auto;
  }
  .navbar-vertical.navbar-expand-xxl.show + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.hide {
    max-width: 270px;
  }
  .navbar-vertical.navbar-expand-xxl.hide .nav-link-text {
    opacity: 0;
    pointer-events: none;
  }
  .navbar-vertical.navbar-expand-xxl.hide + .main-content {
    margin-left: 270px;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    margin-right: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 1;
  }
}
@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }
}
.navbar-vertical.navbar-expand {
  display: block;
  width: 100%;
  max-width: 270px;
  overflow-y: auto;
  z-index: 1000;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand {
    transition: none;
  }
}
.navbar-vertical.navbar-expand + .main-content {
  margin-left: 270px;
}
.navbar-vertical.navbar-expand .nav-link-text,
.navbar-vertical.navbar-expand .navbar-heading {
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-vertical.navbar-expand .nav-link-text,
.navbar-vertical.navbar-expand .navbar-heading {
    transition: none;
  }
}
.navbar-vertical.navbar-expand.show {
  max-width: 270px;
  border-radius: 0;
}
.navbar-vertical.navbar-expand.show.navbar-lg {
  max-width: 320px;
}
.navbar-vertical.navbar-expand.show.navbar-xl {
  max-width: 370px;
}
.navbar-vertical.navbar-expand.show .nav-link-text,
.navbar-vertical.navbar-expand.show .navbar-heading {
  opacity: 1;
  pointer-events: auto;
}
.navbar-vertical.navbar-expand.show + .main-content {
  margin-left: 270px;
}
.navbar-vertical.navbar-expand.hide {
  max-width: 270px;
}
.navbar-vertical.navbar-expand.hide .nav-link-text {
  opacity: 0;
  pointer-events: none;
}
.navbar-vertical.navbar-expand.hide + .main-content {
  margin-left: 270px;
}
.navbar-vertical.navbar-expand .navbar-brand {
  margin-right: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.navbar-vertical.navbar-expand > [class*=container] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}
.navbar-vertical.navbar-expand .navbar-collapse {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  opacity: 1;
}
.navbar-vertical.navbar-expand .navbar-nav {
  margin-left: 0;
  margin-right: 0;
  flex-direction: column;
}
.navbar-toggler {
  padding: 0.25rem 0.375rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 3px;
}
.navbar-light .navbar-toggler {
  color: #6B7B93;
  background-color: transparent;
  border-color: transparent;
}
.navbar-light .navbar-toggler:focus {
  color: transparent;
  background-color: #F5F9FC;
}
.navbar-dark .navbar-toggler {
  color: #E7EAF0;
  background-color: transparent;
  border-color: transparent;
}
.navbar-dark .navbar-toggler:focus {
  color: transparent;
  background-color: #F5F9FC;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #525F7F;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #5C60F5;
}
.nav-link.disabled {
  color: #ABB6C5;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #E7EAF0;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent transparent transparent;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #ABB6C5;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #5C60F5;
  background-color: transparent;
  border-color: transparent transparent #5C60F5;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.375rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #5C60F5;
}
.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
@media (min-width: 992px) {
  .nav-link-badge {
    position: relative;
  }
  .nav-link-badge .badge {
    position: relative;
    top: -15px;
    left: -5px;
  }
  .nav-link-badge .nav-link-text {
    position: relative;
  }
  .nav-link-badge .nav-link-text:before {
    position: absolute;
    top: 0;
    left: -7px;
    display: block;
    width: 20px;
    height: 20px;
    margin: auto;
    content: "";
    transform: translate(-50%, -50%) rotate(100deg);
    border-left: 1px dashed #fff;
    border-radius: 10px;
  }
}
.nav.overflow-x {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px;
}
.nav.overflow-x .nav-item {
  width: auto;
  max-width: 100%;
  flex: 0 0 auto;
}
.nav.overflow-x::-webkit-scrollbar {
  display: none;
}
.nav-header {
  border-bottom-width: 0;
}
.nav-application > .btn {
  width: 123px !important;
  height: 123px !important;
  float: left;
  background: transparent;
  color: #6B7B93;
  margin: 0;
  margin-bottom: 24px;
}
.nav-application > .btn:nth-child(odd) {
  margin-right: 24px;
}
.nav-application > .btn.active {
  background-color: theme-color("primary");
  color: color-yiq(theme-color("primary"));
  box-shadow: 0px 6px 6px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06) !important;
}
.nav-application > .btn:hover:not(.active) {
  color: theme-color("primary");
  transform: scale(1.05);
}
.nav-dots {
  top: 50%;
  right: 2rem;
  position: fixed;
  z-index: 200;
  transform: translateY(-50%);
}
.nav-dots .nav-link {
  display: block;
  margin: 5px;
  position: relative;
}
.nav-dots .nav-link:before {
  content: "";
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  height: 6px;
  width: 6px;
  border: 0;
  background: #FFF;
  left: 50%;
  top: 50%;
  margin: -2px 0 0 -2px;
  transform: translate(-50%, -50%);
  transition: all 0.1s ease-in-out;
}
.nav-dots .nav-link:hover:before, .nav-dots .nav-link.active:before {
  height: 12px;
  width: 12px;
}
.nav-menu .nav-link {
  padding: 0.35rem 0;
}
.nav-menu .nav-link:not(.active):hover {
  color: #2D3748;
}
.nav-menu .nav-link:not(.active).active {
  font-weight: bolder;
}
.nav-tabs .nav-item {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.nav-tabs .nav-item:first-child {
  margin-left: 0;
}
.nav-tabs .nav-item:last-child {
  margin-right: 0;
}
.nav-tabs .nav-item.show .nav-link {
  border-color: transparent;
}
.nav-tabs .nav-link {
  padding: 1.25rem 0;
  border-bottom: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: border-box;
  border: 0 solid #eceef3;
  border-radius: 0.75rem;
  box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
  color: #525F7F;
}
.card-title {
  margin-bottom: 1.5rem;
}
.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link + .card-link {
  margin-left: 1.5rem;
}
.card-header {
  padding: 1.25rem 1.5rem;
  margin-bottom: 0;
  color: #16192C;
  background-color: transparent;
  border-bottom: 0 solid #eceef3;
}
.card-header:first-child {
  border-radius: 0.75rem 0.75rem 0 0;
}
.card-footer {
  padding: 1.25rem 1.5rem;
  color: #16192C;
  background-color: transparent;
  border-top: 0 solid #eceef3;
}
.card-footer:last-child {
  border-radius: 0 0 0.75rem 0.75rem;
}
.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -1.25rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #FFF;
  border-bottom-color: #FFF;
}
.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: 0.75rem;
}
.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}
.card-header-tabs {
  margin-top: calc(-1.5rem - 1px);
  margin-bottom: calc(-1.5rem + 1px);
  margin-left: 0;
  margin-right: 0;
}
.card-header-tabs .nav-item {
  margin-right: 0.75rem;
}
.card-header-tabs .nav-link.active {
  background-color: transparent;
  border-bottom-color: #5C60F5;
}
.card-comment-box {
  width: 100%;
}
.card-comment-box input,
.card-comment-box textarea {
  border: 0;
  box-shadow: none;
  background: transparent;
  padding: 0.3125rem 0;
  margin: 0;
  color: #2D3748;
  outline: none;
  resize: none;
}
.card-comment-box input:focus,
.card-comment-box textarea:focus {
  background: transparent;
  box-shadow: none;
}
.card-list {
  background-color: #FFF;
  border: 0 solid #eceef3;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.75rem;
}
.card-list .card {
  box-shadow: none !important;
}
.card-list-body .card:last-of-type {
  margin-bottom: 0 !important;
}
.empty-container:after {
  pointer-events: none;
  content: attr(data-placeholder);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 500;
  color: #caced0;
  border: 1px dashed #caced0;
  height: 0;
  opacity: 0;
  border-radius: 0.75rem;
  transition: opacity 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .empty-container:after {
    transition: none;
  }
}
.empty-container:only-child:after {
  opacity: 1;
  height: 70px;
}
.card-list-flush {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.card-placeholder {
  padding: 3rem 0;
  background-color: transparent;
  border: 1px dashed #CFD6DF;
}
.card-placeholder:hover {
  border-color: #c1c7cf;
}
.card-placeholder .card-body {
  text-align: center;
}
.card-progress {
  margin-bottom: 0.75rem;
}
.card-progress .card-body {
  padding: 1rem;
}
.card-progress .progress {
  height: 2px;
  margin: 2px 4px;
}
.omnisearch {
  width: 100%;
  margin-top: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  opacity: 0;
  background-color: transparent;
  pointer-events: none;
  transform: translateY(0);
  transition: transform 0.3s, opacity 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .omnisearch {
    transition: none;
  }
}
.omnisearch-container {
  margin: auto;
}
@media (max-width: 767.98px) {
  .omnisearch-container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .omnisearch-container {
    width: 580px;
  }
}
.omnisearch.show {
  opacity: 1;
  transform: translateY(10%);
}
.omnisearch .container {
  position: relative;
  height: 100%;
}
.omnisearch .omnisearch-form {
  display: block;
  position: relative;
  z-index: 700;
  background: #FFF;
  border-radius: 0.5rem;
}
.omnisearch .omnisearch-form .input-group-text {
  font-size: 1.25rem;
  background: transparent;
}
.omnisearch .omnisearch-form .form-control {
  display: block;
  height: 58px;
  font-size: 1.25rem;
  color: #6B7B93;
  background-color: transparent;
  background-image: none;
}
.omnisearch .omnisearch-form .form-control::-moz-placeholder {
  color: #8898A9;
}
.omnisearch .omnisearch-form .form-control::placeholder {
  color: #8898A9;
}
.omnisearch .omnisearch-suggestions {
  min-height: 150px;
  padding: 1.5rem;
  background: white;
  border-radius: 0.5rem;
  position: relative;
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.21s;
}
@media (prefers-reduced-motion: reduce) {
  .omnisearch .omnisearch-suggestions {
    transition: none;
  }
}
.omnisearch .omnisearch-suggestions:before {
  background: #FFF;
  box-shadow: none;
  content: "";
  display: block;
  height: 16px;
  width: 16px;
  left: 20px;
  position: absolute;
  bottom: 100%;
  transform: rotate(-45deg) translateY(1rem);
  z-index: -5;
  border-radius: 0.2rem;
}
.omnisearch .omnisearch-suggestions .heading {
  color: #525F7F;
}
.omnisearch .omnisearch-suggestions .list-link span {
  font-weight: 600;
  color: #2D3748;
}
.omnisearch .omnisearch-suggestions .list-link:hover {
  color: theme-color("primary");
}
.omnisearch .omnisearch-suggestions .list-link:hover span {
  color: theme-color("primary");
}
.omnisearch.show .omnisearch-form,
.omnisearch.show .omnisearch-suggestions {
  pointer-events: auto;
}
.omnisearch.show .omnisearch-suggestions {
  opacity: 1;
}
.omnisearch-open {
  overflow: hidden;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}
.page-link {
  position: relative;
  display: block;
  color: #525F7F;
  background-color: #FFF;
  border: 1px solid #CFD6DF;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #16192C;
  background-color: #FAFAFA;
  border-color: #CFD6DF;
}
.page-link:focus {
  z-index: 3;
  color: #4e52d0;
  background-color: #F5F9FC;
  outline: 0;
  box-shadow: none;
}
.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #FFF;
  background-color: #5C60F5;
  border-color: #5C60F5;
}
.page-item.disabled .page-link {
  color: #6B7B93;
  pointer-events: none;
  background-color: #FFF;
  border-color: #CFD6DF;
}
.page-link {
  padding: 0.375rem 1rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.75rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.pagination {
  margin-bottom: 0;
}
.pagination .page-link {
  font-size: 0.875rem;
}
.pagination-lg .page-link {
  font-size: 1rem;
}
.pagination-sm .page-link {
  font-size: 0.875rem;
}
.pagination-tabs {
  border-radius: 0;
}
.pagination-tabs .page-link {
  margin-top: -1px;
  padding: 1.25rem 0.75rem;
  background-color: transparent;
  border-width: 1px 0 0 0;
  border-color: transparent;
  border-radius: 0 !important;
}
.pagination-tabs .page-link:hover {
  color: #5C60F5;
}
.pagination-tabs .page-item.active .page-link {
  background-color: transparent;
  border-color: #5C60F5;
  color: #FFF;
}
.pagination-spaced {
  border-radius: 0;
}
.pagination-spaced .page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 0.375rem;
  width: 36px;
  height: 36px;
}
.pagination-spaced.pagination-sm .page-link {
  width: 30px;
  height: 30px;
  border-radius: 0.25rem;
}
.pagination-spaced.pagination-lg .page-link {
  width: 52px;
  height: 52px;
}
.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}
.placeholder-xs {
  min-height: 0.6em;
}
.placeholder-sm {
  min-height: 0.8em;
}
.placeholder-lg {
  min-height: 1.2em;
}
.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
          animation: placeholder-glow 2s ease-in-out infinite;
}
@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
          animation: placeholder-wave 2s linear infinite;
}
@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--x-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 2rem 0px rgba(0, 0, 0, 0.2);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: transparent;
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #FFF;
}
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: transparent;
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #FFF;
}
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: transparent;
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #FFF;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #FFF;
}
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: transparent;
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #FFF;
}
.popover-header {
  padding: 0.75rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #16192C;
  background-color: #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.75rem 0.75rem;
  color: #525F7F;
}
.popover-header {
  font-weight: 600;
}
.popover-primary {
  background-color: #5C60F5;
}
.popover-primary .popover-header {
  background-color: #5C60F5;
  color: #FFF;
}
.popover-primary .popover-body {
  color: #FFF;
}
.popover-primary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-primary.bs-popover-top .arrow::after, .popover-primary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #5C60F5;
}
.popover-primary.bs-popover-right .arrow::after {
  border-right-color: #5C60F5;
}
.popover-primary.bs-popover-bottom .arrow::after, .popover-primary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #5C60F5;
}
.popover-primary.bs-popover-left .arrow::after {
  border-left-color: #5C60F5;
}
.popover-secondary {
  background-color: #CFD6DF;
}
.popover-secondary .popover-header {
  background-color: #CFD6DF;
  color: #000;
}
.popover-secondary .popover-body {
  color: #000;
}
.popover-secondary .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-secondary.bs-popover-top .arrow::after, .popover-secondary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #CFD6DF;
}
.popover-secondary.bs-popover-right .arrow::after {
  border-right-color: #CFD6DF;
}
.popover-secondary.bs-popover-bottom .arrow::after, .popover-secondary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #CFD6DF;
}
.popover-secondary.bs-popover-left .arrow::after {
  border-left-color: #CFD6DF;
}
.popover-tertiary {
  background-color: #FF579A;
}
.popover-tertiary .popover-header {
  background-color: #FF579A;
  color: #FFF;
}
.popover-tertiary .popover-body {
  color: #FFF;
}
.popover-tertiary .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-tertiary.bs-popover-top .arrow::after, .popover-tertiary.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #FF579A;
}
.popover-tertiary.bs-popover-right .arrow::after {
  border-right-color: #FF579A;
}
.popover-tertiary.bs-popover-bottom .arrow::after, .popover-tertiary.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #FF579A;
}
.popover-tertiary.bs-popover-left .arrow::after {
  border-left-color: #FF579A;
}
.popover-success {
  background-color: #00CC88;
}
.popover-success .popover-header {
  background-color: #00CC88;
  color: #FFF;
}
.popover-success .popover-body {
  color: #FFF;
}
.popover-success .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-success.bs-popover-top .arrow::after, .popover-success.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #00CC88;
}
.popover-success.bs-popover-right .arrow::after {
  border-right-color: #00CC88;
}
.popover-success.bs-popover-bottom .arrow::after, .popover-success.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #00CC88;
}
.popover-success.bs-popover-left .arrow::after {
  border-left-color: #00CC88;
}
.popover-info {
  background-color: #00D4FF;
}
.popover-info .popover-header {
  background-color: #00D4FF;
  color: #000;
}
.popover-info .popover-body {
  color: #000;
}
.popover-info .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-info.bs-popover-top .arrow::after, .popover-info.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #00D4FF;
}
.popover-info.bs-popover-right .arrow::after {
  border-right-color: #00D4FF;
}
.popover-info.bs-popover-bottom .arrow::after, .popover-info.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #00D4FF;
}
.popover-info.bs-popover-left .arrow::after {
  border-left-color: #00D4FF;
}
.popover-warning {
  background-color: #FF8C00;
}
.popover-warning .popover-header {
  background-color: #FF8C00;
  color: #FFF;
}
.popover-warning .popover-body {
  color: #FFF;
}
.popover-warning .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-warning.bs-popover-top .arrow::after, .popover-warning.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #FF8C00;
}
.popover-warning.bs-popover-right .arrow::after {
  border-right-color: #FF8C00;
}
.popover-warning.bs-popover-bottom .arrow::after, .popover-warning.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #FF8C00;
}
.popover-warning.bs-popover-left .arrow::after {
  border-left-color: #FF8C00;
}
.popover-danger {
  background-color: #FF3366;
}
.popover-danger .popover-header {
  background-color: #FF3366;
  color: #FFF;
}
.popover-danger .popover-body {
  color: #FFF;
}
.popover-danger .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-danger.bs-popover-top .arrow::after, .popover-danger.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #FF3366;
}
.popover-danger.bs-popover-right .arrow::after {
  border-right-color: #FF3366;
}
.popover-danger.bs-popover-bottom .arrow::after, .popover-danger.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #FF3366;
}
.popover-danger.bs-popover-left .arrow::after {
  border-left-color: #FF3366;
}
.popover-white {
  background-color: #FFF;
}
.popover-white .popover-header {
  background-color: #FFF;
  color: #000;
}
.popover-white .popover-body {
  color: #000;
}
.popover-white .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-white.bs-popover-top .arrow::after, .popover-white.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #FFF;
}
.popover-white.bs-popover-right .arrow::after {
  border-right-color: #FFF;
}
.popover-white.bs-popover-bottom .arrow::after, .popover-white.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #FFF;
}
.popover-white.bs-popover-left .arrow::after {
  border-left-color: #FFF;
}
.popover-light {
  background-color: #FFF;
}
.popover-light .popover-header {
  background-color: #FFF;
  color: #000;
}
.popover-light .popover-body {
  color: #000;
}
.popover-light .popover-header {
  border-color: rgba(0, 0, 0, 0.2);
}
.popover-light.bs-popover-top .arrow::after, .popover-light.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #FFF;
}
.popover-light.bs-popover-right .arrow::after {
  border-right-color: #FFF;
}
.popover-light.bs-popover-bottom .arrow::after, .popover-light.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #FFF;
}
.popover-light.bs-popover-left .arrow::after {
  border-left-color: #FFF;
}
.popover-dark {
  background-color: #16192C;
}
.popover-dark .popover-header {
  background-color: #16192C;
  color: #FFF;
}
.popover-dark .popover-body {
  color: #FFF;
}
.popover-dark .popover-header {
  border-color: rgba(255, 255, 255, 0.2);
}
.popover-dark.bs-popover-top .arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=top] .arrow::after {
  border-top-color: #16192C;
}
.popover-dark.bs-popover-right .arrow::after {
  border-right-color: #16192C;
}
.popover-dark.bs-popover-bottom .arrow::after, .popover-dark.bs-popover-auto[data-popper-placement^=bottom] .arrow::after {
  border-bottom-color: #16192C;
}
.popover-dark.bs-popover-left .arrow::after {
  border-left-color: #16192C;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 0.5rem;
  }
}
.progress {
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #E7EAF0;
  border-radius: 50rem;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  background-color: theme-color("primary");
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.5rem 0.5rem;
}
.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}
.progress-wrapper {
  position: relative;
  padding-top: 1.5rem;
}
.progress-wrapper .progress {
  margin-bottom: 1rem;
}
.progress-inverse {
  background-color: inverse(#E7EAF0);
}
.progress-heading {
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0 0 2px;
  padding: 0;
}
.progress-text {
  margin-bottom: 0;
}
.progress-lg {
  height: 1rem;
}
.progress-md {
  height: 0.75rem;
}
.progress-sm {
  height: 0.375rem;
}
.progress-xs {
  height: 0.125rem;
}
.progress-group {
  position: relative;
}
.progress-prepend-icon {
  position: absolute;
  transform: translateY(-50%);
  font-size: 20px;
  top: 50%;
}
.progress-prepend-icon:not(:first-child) {
  right: -17px;
}
.progress-prepend-icon:not(:last-child) {
  left: -17px;
}
.progress-circle {
  width: 100px;
  height: 100px;
}
.progress-circle .progressbar-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #16192C;
}
.progress-circle [class^=display],
.progress-circle .h1, .progress-circle .h2, .progress-circle .h3 {
  color: #16192C;
}
.progress-circle svg path {
  stroke-linecap: round;
}
.progress-circle.progress-sm {
  width: 60px;
  height: 60px;
}
.progress-circle.progress-sm .progressbar-text {
  font-size: 0.875rem;
}
.progress-circle.progress-lg {
  width: 140px;
  height: 140px;
}
.progress-circle.progress-lg .progressbar-text {
  font-size: 1.25rem;
}
.progress-label {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  color: #16192C;
}
.progress-percentage {
  display: inline-block;
  position: absolute;
  right: 0;
  top: 0;
}
.progress-tooltip {
  display: inline-block;
  background: rgba(0, 0, 0, 0.8);
  color: #FFF;
  padding: 0.25rem 0.375rem;
  line-height: 1;
  font-size: 0.7rem;
  position: relative;
  bottom: 8px;
  border-radius: 3px;
  margin-left: -15px;
}
.progress-tooltip:after {
  top: 100%;
  left: 10px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(51, 51, 51, 0);
  border-top-color: rgba(0, 0, 0, 0.8);
  border-width: 5px;
}
.shape-container {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  transform: translateZ(0);
  overflow: hidden;
  color: #FFF;
}
.shape-container svg {
  display: block;
  pointer-events: none;
  vertical-align: baseline;
}
.shape-position-top {
  top: 0;
  margin-top: -1px;
}
.shape-position-bottom {
  bottom: 0;
  margin-bottom: -1px;
}
.shape-orientation-inverse {
  transform: rotate(180deg);
}
.shape-line {
  min-height: 19px;
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
          animation: 0.75s linear infinite spinner-border;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
          animation: 0.75s linear infinite spinner-grow;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
  }
}
.table {
  --x-table-bg: transparent;
  --x-table-accent-bg: transparent;
  --x-table-striped-color: #525F7F;
  --x-table-striped-bg: rgba(0, 0, 0, 0.05);
  --x-table-active-color: #525F7F;
  --x-table-active-bg: rgba(0, 0, 0, 0.1);
  --x-table-hover-color: #525F7F;
  --x-table-hover-bg: rgba(0, 0, 0, 0.02);
  width: 100%;
  margin-bottom: 1rem;
  color: #525F7F;
  vertical-align: middle;
  border-color: #E7EAF0;
}
.table > :not(caption) > * > * {
  padding: 1rem 1.5rem;
  background-color: var(--x-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--x-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid #E7EAF0;
}
.caption-top {
  caption-side: top;
}
.table-sm > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
}
.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}
.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --x-table-accent-bg: var(--x-table-striped-bg);
  color: var(--x-table-striped-color);
}
.table-active {
  --x-table-accent-bg: var(--x-table-active-bg);
  color: var(--x-table-active-color);
}
.table-hover > tbody > tr:hover > * {
  --x-table-accent-bg: var(--x-table-hover-bg);
  color: var(--x-table-hover-color);
}
.table-primary {
  --x-table-bg: #dedffd;
  --x-table-striped-bg: #d3d4f0;
  --x-table-striped-color: #000;
  --x-table-active-bg: #c8c9e4;
  --x-table-active-color: #000;
  --x-table-hover-bg: #dadbf8;
  --x-table-hover-color: #000;
  color: #000;
  border-color: #c8c9e4;
}
.table-secondary {
  --x-table-bg: #f5f7f9;
  --x-table-striped-bg: #e9ebed;
  --x-table-striped-color: #000;
  --x-table-active-bg: #dddee0;
  --x-table-active-color: #000;
  --x-table-hover-bg: #f0f2f4;
  --x-table-hover-color: #000;
  color: #000;
  border-color: #dddee0;
}
.table-success {
  --x-table-bg: #ccf5e7;
  --x-table-striped-bg: #c2e9db;
  --x-table-striped-color: #000;
  --x-table-active-bg: #b8ddd0;
  --x-table-active-color: #000;
  --x-table-hover-bg: #c8f0e2;
  --x-table-hover-color: #000;
  color: #000;
  border-color: #b8ddd0;
}
.table-info {
  --x-table-bg: #ccf6ff;
  --x-table-striped-bg: #c2eaf2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #b8dde6;
  --x-table-active-color: #000;
  --x-table-hover-bg: #c8f1fa;
  --x-table-hover-color: #000;
  color: #000;
  border-color: #b8dde6;
}
.table-warning {
  --x-table-bg: #ffe8cc;
  --x-table-striped-bg: #f2dcc2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e6d1b8;
  --x-table-active-color: #000;
  --x-table-hover-bg: #fae3c8;
  --x-table-hover-color: #000;
  color: #000;
  border-color: #e6d1b8;
}
.table-danger {
  --x-table-bg: #ffd6e0;
  --x-table-striped-bg: #f2cbd5;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e6c1ca;
  --x-table-active-color: #000;
  --x-table-hover-bg: #fad2dc;
  --x-table-hover-color: #000;
  color: #000;
  border-color: #e6c1ca;
}
.table-light {
  --x-table-bg: #FFF;
  --x-table-striped-bg: #f2f2f2;
  --x-table-striped-color: #000;
  --x-table-active-bg: #e6e6e6;
  --x-table-active-color: #000;
  --x-table-hover-bg: #fafafa;
  --x-table-hover-color: #000;
  color: #000;
  border-color: #e6e6e6;
}
.table-dark {
  --x-table-bg: #16192C;
  --x-table-striped-bg: #222537;
  --x-table-striped-color: #FFF;
  --x-table-active-bg: #2d3041;
  --x-table-active-color: #FFF;
  --x-table-hover-bg: #1b1e30;
  --x-table-hover-color: #FFF;
  color: #FFF;
  border-color: #2d3041;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.table {
  margin-bottom: 0;
}
.table thead th {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.675rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  border-bottom-width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.table tbody th {
  font-size: 0.8125rem;
}
.table td {
  font-size: 0.8125rem;
  white-space: nowrap;
}
.table td .progress {
  height: 3px;
  width: 120px;
  margin: 0;
}
.table.table-dark th,
.table .table-dark th {
  background-color: #1f2234;
  color: #686a76;
}
.table.table-dark th a,
.table .table-dark th a {
  color: #686a76;
}
.table.table-light th,
.table .table-light th {
  background-color: #F5F9FC;
  color: #525F7F;
}
.table.table-light th a,
.table .table-light th a {
  color: #525F7F;
}
.table-spaced {
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.table-spaced thead th {
  border: 0;
}
.table-spaced thead tr th,
.table-spaced thead tr td,
.table-spaced tbody tr th,
.table-spaced tbody tr td {
  position: relative;
  background-color: transparent;
  border-top: 1px solid #E7EAF0 !important;
  border-bottom: 1px solid #E7EAF0 !important;
}
.table-spaced thead tr th:first-child,
.table-spaced thead tr td:first-child,
.table-spaced tbody tr th:first-child,
.table-spaced tbody tr td:first-child {
  border-left: 1px solid #E7EAF0 !important;
  border-radius: 0.375rem 0 0 0.375rem;
}
.table-spaced thead tr th:first-child:after,
.table-spaced thead tr td:first-child:after,
.table-spaced tbody tr th:first-child:after,
.table-spaced tbody tr td:first-child:after {
  border-left: 1px solid #E7EAF0;
  border-radius: 0.375rem 0 0 0.375rem;
}
.table-spaced thead tr th:last-child,
.table-spaced thead tr td:last-child,
.table-spaced tbody tr th:last-child,
.table-spaced tbody tr td:last-child {
  border-right: 1px solid #E7EAF0 !important;
  border-radius: 0 0.375rem 0.375rem 0;
}
.table-spaced tbody tr {
  box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
}
.table-flush th, .table-flush td {
  border-left: 0;
  border-right: 0;
}
.table-flush thead > tr th {
  border-top: 0;
}
.table-flush tbody > tr:last-child th, .table-flush tbody > tr:last-child td {
  border-bottom: 0;
}
.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 3px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
  border-radius: 0.375rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}
.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 1.5rem;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6B7B93;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}
.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--x-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.625;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem;
}
.article {
  font-size: 1rem;
  color: #525F7F;
  line-height: 1.9;
}
.article > p {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.article > h2, .article > .h2 {
  font-size: 1.5em;
  font-weight: bolder;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
  letter-spacing: -0.025em;
}
.article > h2:first-child, .article > .h2:first-child {
  margin-top: 1em;
}
.article > h3, .article > .h3 {
  font-size: 1.25em;
  font-weight: 600;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
}
.article > h4, .article > .h4 {
  font-size: 1em;
  font-weight: 500;
  margin-top: 2em;
}
.article > img {
  border-radius: 0.375rem;
}
.article > figure,
.article > img,
.article > video {
  margin-top: 2em;
  margin-bottom: 2em;
}
.article pre {
  font-size: 0.875em;
  line-height: 1.7;
  padding: 1rem 1rem;
  margin-top: 1.75em;
  margin-bottom: 1.75em;
  overflow-x: auto;
  background-color: #16192C;
  color: #FFF;
  border-radius: 0.375rem;
}
.article > :first-child {
  margin-top: 0;
}
.article h2 + *, .article .h2 + *,
.article h3 + *,
.article .h3 + *,
.article hr + * {
  margin-top: 0;
}
.article > hr {
  margin-top: 3em;
  margin-bottom: 3em;
}
.article > ul,
.article > ol {
  padding-left: 1.5rem;
}
.article > ul li,
.article > ol li {
  padding: 0.25rem 0;
}
.article > blockquote {
  font-weight: 500;
  font-size: 1.25rem;
  color: #16192C;
  letter-spacing: -0.012em;
  padding: 1.5rem 3.5rem;
  margin-top: 3em;
  margin-bottom: 3em;
  background-color: var(--x-surface-secondary);
  border-radius: 0.5rem;
}
.article > blockquote > p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #fff !important;
  font-weight: 500;
  color: #3f4254;
  border-bottom: 1px solid #f5f8fa !important;
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  padding: 0.5rem 1rem;
}
.apexcharts-xaxistooltip.apexcharts-theme-light {
  border-radius: 0.475rem !important;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15) !important;
  border: 0 !important;
  background: #fff !important;
  color: #3f4254;
}
.apexcharts-xaxistooltip.apexcharts-theme-light:before {
  border-bottom: 0 !important;
}
.apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: #fff !important;
}
.card-rounded-bottom .apexcharts-canvas svg {
  border-bottom-left-radius: 0.475rem;
  border-bottom-right-radius: 0.475rem;
}
.rounded .apexcharts-canvas svg {
  border-radius: 0.475rem !important;
}
.rounded-sm .apexcharts-canvas svg {
  border-radius: 0.275rem !important;
}
.rounded-lg .apexcharts-canvas svg {
  border-radius: 0.775rem !important;
}
.rounded-xl .apexcharts-canvas svg {
  border-radius: 1rem !important;
}
.apexcharts-bar-hover-white .apexcharts-bar-area:hover {
  fill: #fff !important;
}
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  box-sizing: border-box;
  touch-action: manipulation;
  background: #fff;
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}
.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}
.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}
.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}
.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}
.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  box-shadow: none !important;
}
.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}
.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}
.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}
.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}
.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}
.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}
.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}
.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}
.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}
.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}
.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}
.flatpickr-calendar:focus {
  outline: 0;
}
.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}
.flatpickr-months {
  display: flex;
}
.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  overflow: hidden;
  flex: 1;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}
.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}
.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}
/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}
.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}
.numInputWrapper {
  position: relative;
  height: auto;
}
.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}
.numInputWrapper input {
  width: 100%;
}
.numInputWrapper input::-ms-clear {
  display: none;
}
.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  box-sizing: border-box;
}
.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}
.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}
.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}
.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}
.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}
.numInputWrapper span.arrowDown {
  top: 50%;
}
.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}
.numInputWrapper span svg {
  width: inherit;
  height: auto;
}
.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}
.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}
.numInputWrapper:hover span {
  opacity: 1;
}
.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
}
.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}
.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}
.flatpickr-current-month input.cur-year {
  background: transparent;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}
.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  height: 28px;
}
.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}
span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
  font-weight: bolder;
}
.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}
.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: 307.875px;
}
.flatpickr-days:focus {
  outline: 0;
}
.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  box-sizing: border-box;
  display: inline-block;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}
.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}
.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  justify-content: center;
  text-align: center;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.flatpickr-day.today {
  border-color: #959ea9;
}
.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}
.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}
.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  box-shadow: -10px 0 0 #569ff7;
}
.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}
.flatpickr-day.inRange {
  border-radius: 0;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}
.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}
.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}
.flatpickr-day.hidden {
  visibility: hidden;
}
.rangeMode .flatpickr-day {
  margin-top: 1px;
}
.flatpickr-weekwrapper {
  float: left;
}
.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}
.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}
.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}
.flatpickr-innerContainer {
  display: block;
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
}
.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  box-sizing: border-box;
}
.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
}
.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}
.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}
.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}
.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}
.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}
.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}
.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}
.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}
.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}
.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  align-self: center;
}
.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}
.flatpickr-input[readonly] {
  cursor: pointer;
}
@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar {
  border: 0;
  width: auto;
  margin-top: -2px;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background: #FFF;
  box-shadow: 0px 16px 16px -1px rgba(10, 22, 70, 0.1), 0px 0px 1px 0px rgba(10, 22, 70, 0.06);
  border-radius: 0.75rem;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  display: none;
}
.flatpickr-months {
  background-color: #FFF;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.flatpickr-months .flatpickr-month {
  height: 60px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  width: 35px;
  height: 35px;
  padding: 0;
  line-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #16192C;
  top: 13px;
  transition: background-color 0.4s linear;
}
@media (prefers-reduced-motion: reduce) {
  .flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
    transition: none;
  }
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: rgba(22, 25, 44, 0.7);
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #16192C;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #16192C;
}
.flatpickr-months .flatpickr-prev-month {
  margin-left: 15px;
}
.flatpickr-months .flatpickr-next-month {
  margin-right: 15px;
}
.flatpickr-current-month {
  font-size: 1.125rem;
  color: #16192C;
  padding-top: 18px;
}
.flatpickr-current-month span.cur-month:hover {
  background-color: transparent;
}
.flatpickr-current-month .numInputWrapper:hover {
  background-color: transparent;
}
.flatpickr-current-month .numInputWrapper span {
  border: 0;
  right: -5px;
  padding: 0;
}
.flatpickr-current-month .numInputWrapper span:after {
  left: 3px;
}
.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(255, 255, 255, 0.7);
}
.flatpickr-current-month .numInputWrapper span.arrowUp:hover:after {
  border-bottom-color: #FFF;
}
.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(255, 255, 255, 0.7);
}
.flatpickr-current-month .numInputWrapper span.arrowDown:hover:after {
  border-top-color: #FFF;
}
span.flatpickr-weekday {
  font-weight: 600;
  color: #16192C;
}
.flatpickr-day {
  font-size: 0.875rem;
  border: 0;
  color: #16192C;
  border-radius: 0.375rem;
}
.flatpickr-day.today {
  color: #5C60F5 !important;
}
.flatpickr-day.today:hover {
  background-color: transparent;
  color: theme-color("primary");
}
.flatpickr-day:hover {
  background-color: transparent;
  color: theme-color("primary");
}
.flatpickr-day.selected {
  background-color: #5C60F5;
  color: #FFF !important;
}
.flatpickr-day.selected:hover {
  background-color: #5C60F5;
  color: #FFF;
}
.numInputWrapper span:hover {
  background-color: transparent;
}
.flatpickr-time {
  border-top: 1px solid #f2f3f7;
}
.flatpickr-innerContainer {
  padding: 15px;
}
/*# sourceMappingURL=main.css.map */

// Button for toggling the navbar when in its collapsed state
.navbar-toggler {
    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    @include font-size($navbar-toggler-font-size);
    line-height: 1;
    background-color: transparent; // remove default button style
    border: $navbar-toggler-border-width solid transparent; // remove default button style
    @include border-radius($navbar-toggler-border-radius);
    @include transition($navbar-toggler-transition);

    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: 0 0 0 $navbar-toggler-focus-width;
    }
}

.navbar-light {
    .navbar-toggler {
        color: $navbar-light-toggler-color;
        background-color: $navbar-light-toggler-bg;
        border-color: $navbar-light-toggler-border-color;

        &:focus {
            color: $navbar-light-toggler-focus-color;
            background-color: $navbar-light-toggler-focus-bg;
        }
    }
}

.navbar-dark {
    .navbar-toggler {
        color: $navbar-dark-toggler-color;
        background-color: $navbar-dark-toggler-bg;
        border-color: $navbar-dark-toggler-border-color;

        &:focus {
            color: $navbar-dark-toggler-focus-color;
            background-color: $navbar-dark-toggler-focus-bg;
        }
    }
}

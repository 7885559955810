//
// Button square
//

.btn-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $btn-square-width;
    height: $btn-square-width;
    padding: 0;

    &.btn-xs {
        width: $btn-square-width-xs;
        height: $btn-square-width-xs;
    }

    &.btn-sm {
        width: $btn-square-width-sm;
        height: $btn-square-width-sm;
    }

    &.btn-lg {
        width: $btn-square-width-lg;
        height: $btn-square-width-lg;
    }

    &.btn-xl {
        width: $btn-square-width-xl;
        height: $btn-square-width-xl;
    }

    &.btn-2xl {
        width: $btn-square-width-2xl;
        height: $btn-square-width-2xl;
    }
}

//
// Pagination

@import "bootstrap/scss/pagination";

.pagination {
    margin-bottom: 0;

    .page-link {
        font-size: $pagination-font-size;
    }
}


// Sizing

.pagination-lg .page-link {
    font-size: $pagination-font-size-lg;
}

.pagination-sm .page-link {
    font-size: $pagination-font-size-sm;
}

// This mixin uses an `if()` technique to be compatible with Dart Sass

@mixin input-group-validation-state(
    $state,
    $color,
    $icon,
    $focus-box-shadow: 0 0 $input-btn-focus-blur $input-focus-width rgba($color, $input-btn-focus-color-opacity)
) {

    .input-group {
        @include form-validation-state-selector($state) {
            .form-control,
            .form-select,
            .input-group-text {
                border-color: $color;
            }

            &:focus-within {
                border-color: $color;
                box-shadow: $focus-box-shadow;
            }
        }
    }
}
